<template>
  <div class="text-center mb-6">
    <h3 class="mb-3">Withdrawal History</h3>
    <div v-if="hasList">
      <v-divider color="#333"></v-divider>
      <div class="list-history pa-3" v-for="(withdraw, index) in lists" :key="index">
        <div class="d-flex text-left">
          <div style="flex:1 1 100px">
            {{ formatMoney(parseFloat(withdraw.amount)) }}
          </div>
           <div style="flex:0 0 80px" class="with-info text-right">
            <span :class="`${withdraw.status.toLowerCase()}-status`">
              {{ withdraw.status_label === 'Unknown' ? '' : withdraw.status_label }}
            </span>
          </div>
        </div>
        <div class="d-flex text-left">
          <div class="with-info grey--text" v-if="withdraw.outlet === 'bank' && withdraw.additional_info">
            {{ withdraw.additional_info.bank }}
          </div>
          <div class="with-info grey--text" v-else-if="withdraw.outlet_label" style="flex:1 1 100px">
            {{ withdraw.outlet_label }}
          </div>
          <div 
            class="with-info text-right" 
            style="flex:0 0 100px; line-height: 1em" 
            v-if="withdraw.outlet == 'palawan' || withdraw.outlet == 'cebuana' || withdraw.outlet == 'mlhuillier'">
             
          </div>
        </div>
        <div class="text-left with-info grey--text">
          <div v-if="withdraw.outlet == 'bank' && withdraw.additional_info">
              {{ withdraw.additional_info.acc_name }} - {{ withdraw.additional_info.acc_num }}
          </div>
          <div v-else-if="withdraw.outlet == 'coordinator'">
              Ref# : {{ withdraw.reference_id }}
          </div>
          <div v-else-if="withdraw.additional_info">
              {{ withdraw.additional_info.acc }}
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="lists < 1" class="text-lose">
      <small>{{ loading ? 'Loading...' : 'No withdrawals were made yet' }}</small>
    </div>
    <div v-if="hasMore" class="my-3 text-center">
      <v-btn text small :loading="loading" @click.stop.prevent="loadMore()">Show More</v-btn>
    </div>
  </div>
</template>

<script>
import list from "./../../../mixins/list";
import helpers from "./../../../mixins/helpers";

export default {
  mixins: [list, helpers],
  data: () => ({
    dataUrl: `/wallet/withdrawals`,
    actionSub: null
  }),
  mounted() {
    this.actionSub = this.$store.subscribeAction(action => {
      if (action.type === "appEvent") {
        this.getList(this.listPage = 1)
      }
    });
  },
  beforeDestroy() {
    this.actionSub()
  }
};
</script>