<template>
    <v-container fluid>
        <page-header title="Promo" back-button="account" v-if="mobile" />
        <v-overlay :value="overlay" >
            <div v-if="selectedImage" style="max-width: 750px;" class="text-center">
                <img :src="selectedImage" contain alt="" style="height: 60vh; width: 95%;"  @click.stop="selectedImage = null ||(overlay = false) ">
                <div style="width:95%; margin: auto; margin-top: -7px;">
                    <v-btn
                        @click.stop="popPromos = null ||(overlay = false) || (overlay= null)"
                        small
                        block
                        tile>Close
                    </v-btn>
                </div>
            </div>
        </v-overlay>
        <v-row class="ma-4" v-if="mobile" >

            <v-col v-for="(promo,index) in promos" :key="index" class="d-flex child-flex" sm="12" md="6" lg="4"  cols="12"  >
                <v-img v-if="promos"
                       :src="promo.content_url"
                       :aspect-ratio="1.3"
                       height="100%"
                       max-width="750" max-heigth="950"
                       contain
                       @click="zoom(promo.content_url) || (overlay= !overlay)"
                >
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                                    indeterminate
                                    color="grey lighten-2"
                            ></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </v-col>

        </v-row>
        <v-row class="ma-4" v-else >

            <v-col v-for="(promo,index) in promos" :key="index" class="d-flex child-flex" sm="12" md="12" lg="12"  cols="12"  >
                <v-img v-if="promos"
                       :src="promo.content_url"
                       :aspect-ratio="1.3"
                       height="100%"
                       contain
                       max-width="750" max-heigth="950"
                       @click="zoom(promo.content_url) || (overlay= !overlay)"
                >
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                                    indeterminate
                                    color="grey lighten-2"
                            ></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </v-col>

        </v-row>

    </v-container>

</template>

<script>
    import mixin from "./../mixins";
    import data from "./../mixins/data";
    import helpers from "./../mixins/helpers";
    import rules from "./../mixins/rules";
    import PageHeader from "@/sections/PageHeader";
    export default {
        mixins: [mixin, data, helpers, rules],
        data: () => ({
            promos:[],
            overlay:null,
            selectedImage:null,
        }),
        components: {
            PageHeader
        },
        methods: {
            zoom(promo) {
                // console.log("Zoom", promo);
                this.selectedImage = promo;
            }
        },
        computed: {
            mobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
        },
        mounted() {
        console.log(this.promos)
            this.getData('/promos',
                response => {
                    this.promos = response.promos;


                },
                () => {
                    this.redirectTo404()
                }
            )
        }
    }
</script>