<template>
    <div class="">
        <page-header title="Add Points" back-button="account" v-if="mobile" />
        <v-container class="pa-3 ma-0" fluid>
            <template>
                <!--         <h3>Select Outlet</h3>
                        <v-row>
                          <v-col cols="12">
                            <div
                              class="d-flex justify-space-between align-center "
                              style="margin:0 -5px;"
                            >
                              <v-card
                                v-for="(outlet, index) in outlets"
                                :key="index"
                                class="pa-2 credits-tab"
                                @click="setOutlet(outlet.id)"
                                flat
                                :class="{ active: deposit.outlet === outlet.id }"
                              >
                                <div class="text-center">
                                  <v-avatar>
                                    <img :src="getLogo(outlet.id)" />
                                  </v-avatar>
                                </div>
                              </v-card>
                            </div>
                          </v-col>
                        </v-row> -->
                <div v-if="uploadedGcashReceipt">

                    <v-img
                           max-height="580"
                           height="78vh"
                           width="100vh"
                           class="text-center"
                           :aspect-ratio="0.7"
                           :src="deposit.attachment"
                           contain

                    ></v-img>
                </div>
               <div v-if="!uploadedGcashReceipt">
                   <div class="mb-3 mt-4 text-left outlet-details">
                       <strong class="font-weight-light text-uppercase body-2">Transaction Type</strong>
                       <h3 class="font-weight-medium yellow--text mt-2">DEPOSIT</h3>
                   </div>
               </div>


                <!--        <form action="https://www.coinpayments.net/index.php" method="post" class="text-center">-->
                <!--            <input type="hidden" name="cmd" value="_pay_simple">-->
                <!--            <input type="hidden" name="reset" value="1">-->
                <!--            <input type="hidden" name="merchant" value="49f9770113601886978117e10b88b8ff">-->
                <!--            <input type="hidden" name="item_name" value="50 credits">-->
                <!--            <input type="hidden" name="item_desc" value="50 credits">-->
                <!--            <input type="hidden" name="item_number" value="SI-50">-->
                <!--            <input type="hidden" name="currency" value="USD">-->
                <!--            <input type="hidden" name="amountf" value="10.00000000">-->
                <!--            <input type="hidden" name="want_shipping" value="0">-->
                <!--            <input type="image" src="https://www.coinpayments.net/images/pub/buynow-med-grey.png" alt="Buy Now with CoinPayments.net">-->
                <!--        </form>-->

                <!--        <p class="text-center">OR</p>-->
            <div v-if="!uploadedGcashReceipt">
                <div class="gateway-logo mt-2 mb-3 text-center" v-if="outlet && !outlet.gateway">
                    <img class="elevation-1" :src="getLogo(outlet.id)" />
                </div>


                <v-card color="#3963a5" class="mb-4" v-if="outlet && !outlet.gateway " flat>
                    <v-card-actions>
                        <v-btn color="#fff" text>
                            INSTRUCTIONS
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="showInstruction = !showInstruction">
                            <v-icon>
                                {{ showInstruction ? "mdi-chevron-up" : "mdi-chevron-down" }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="showInstruction" v-if="user.can_deposit">
                            <v-card-text
                                    style="background-color:#a1dafb; color:#333"
                                    class="pb-1"
                                    v-if="outlet.receivers.length > 0 || outlet.id === 'si'"
                            >

                                <div class="" v-if="outlet.id !== 'si'">
                                    <p class="body-2 mb-2">
                                        Send amount of
                                        <span class="amount font-weight-bold">
                                          {{ formatMoney(outlet.minimum) }}
                                        </span> - 
                                        <span class="amount font-weight-bold">
                                          {{ formatMoney(outlet.maximum) }}
                                        </span>
                                        to any of the numbers below
                                    </p>
                                    <div v-for="(rec, index) in outlet.receivers" :key="index">
                                        <v-card
                                                color="#fff3ba"
                                                class="pa-2 mb-2 d-flex align-center black--text"
                                                flat
                                                v-if="rec.name"
                                        >
                                            <template v-if="rec.address">
                                                <div class="">
                                                    <h4>{{ rec.name }}</h4>
                                                    <div class="small">Address: {{ rec.address }}</div>
                                                    <div class="small">
                                                        Phone:
                                                        <span class="phone-number">{{ rec.number }}</span>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="">
                                                    <h4 class="phone-number">{{ rec.number }}</h4>
                                                    <div class="small">{{ rec.name }}</div>
                                                </div>
                                            </template>
                                            <v-spacer></v-spacer>
                                            <div class="black--text">
                                                <v-avatar>
                                                    <v-icon size="35" color="#333">
                                                        mdi-account-circle
                                                    </v-icon>
                                                </v-avatar>
                                            </div>
                                        </v-card>
                                    </div>
                                    <template v-if="instructions[outlet.id]">
                                        <div
                                                class="instructions mb-2"
                                                v-for="instruction in instructions[outlet.id]"
                                                :key="instruction"
                                        >
                                            {{ instruction }}
                                        </div>
                                    </template>
                                </div>
                            </v-card-text>
                            <v-card-text v-else>
                                {{ outlet.name }} is currently unavailable
                            </v-card-text>
                        </div>
                    </v-expand-transition>
                </v-card>
            </div>

                <div
                        v-if="outlet && outlet.gateway"

                        class="mb-0 pb-4 outlet-form"
                        flat
                >
                    <!-- <div v-if="gateway && gateway.url">
                      <p class="mt-3">{{ gateway.message }}</p>
                      <v-btn
                        tile
                        block
                        color="primary"
                        :loading="busy"
                        @click="openGateway(gateway.url)"
                      >
                        {{ gateway.url }}
                      </v-btn>
                      <v-sheet light class="notes mb-0 mt-5 pa-3">
                        Ang mga "credits" ay awtomatiko madadagdag sa inyong account sa loob ng 2-5 minutos
                        matapos ma kumpleto ang iyong bayad.
                      </v-sheet>
                    </div> -->
                    <div>
                        <div class="gateway-logo mt-3 text-center">
                            <img class="elevation-1" :src="getLogo(outlet.id)" />
                        </div>
                        <!-- <h3 class="mt-2">Deposit Form</h3> -->
                        <v-form
                                ref="form"
                                v-model="valid2"
                                lazy-validation
                                class="mb-0 mt-5 wallet-form"
                                @submit="submit('gateway')"
                        >
                            <p class="my-2 body-2">Amount</p>
                            <v-text-field
                                    class="mb-0"
                                    :label="amountLabel"
                                    type="number"
                                    background-color="#58658d"
                                    placeholder="0"
                                    single-line
                                    dense
                                    solo
                                    autocomplete="off"
                                    v-model="deposit.amount"
                                    :rules="[rules.required, depositAmountRule]"
                                    :error-messages="error && error.amount ? error.amount[0] : ''"
                                    :loading="busy"
                            />
                        </v-form>
                        <!-- <v-sheet light class="notes mb-5 pa-3 text-left">
                          Siguraduhin mayroong sapat na "credits" sa iyong account bago magpatuloy.
                          Ang pag-abandona ng proseso ng pag-bayad ay magiging "invalid"
                        </v-sheet> -->
                        <v-btn
                                tile
                                block
                                color="primary"
                                :loading="busy"
                                @click="submit('gateway')">
                            CONTINUE
                        </v-btn>
                    </div>
                </div>
                <v-sheet
                        v-else-if="outlet && outlet.receivers.length > 0 && user.can_deposit"
                        color="#3a4b7b"
                        class="mb-4 pa-3 pb-4  outlet-form"
                >
                    <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            class="mb-5 mt-1 wallet-form"
                            @submit="submit()"
                    >
                        <div v-if="uploadedGcashReceipt==true">
                            <v-btn tile block color="primary"
                                   v-if="uploadedGcashReceipt && outlet.receivers.length > 0 "
                                   :loading="busy" @click="changeReceipt()"
                            >Change Receipt Image
                            </v-btn
                            >
                            <p class="body-2 mb-1 mt-2 white--text">Amount To Load</p>
                            <v-text-field
                                    background-color="#c0c2ce33"
                                    type="number"
                                    placeholder="0"
                                    single-line
                                    dense
                                    solo
                                    hide-details="auto"
                                    v-model="deposit.amount"
                                    :rules="[rules.required, depositAmountRule]"
                                    :error-messages="error && error.amount ? error.amount[0] : ''"
                                    :loading="busy"
                            />
                            <template v-if="outlet.reqSender && outlet.id === 'gcash'">
                                <p class="body-2 mb-1 white--text">Sender Number<span
                                        class="subtitle-2 text-caption yellow--text text--darken-2 font-italic ">***Your Gcash Number</span>
                                </p>
                                <v-text-field
                                        type="number"
                                        background-color="#c0c2ce33"
                                        single-line
                                        dense
                                        solo
                                        hide-details="auto"
                                        v-model="deposit.sender"
                                        :rules="[rules.required, rules.phone]"
                                        :error-messages="error && error.sender ? error.sender[0] : ''"
                                        :loading="busy"
                                />
                            </template>
                            <template v-else-if="outlet.reqSender">
                                <p class="body-2 mb-1 white--text">Sender</p>
                                <v-text-field
                                        single-line
                                        background-color="#c0c2ce33"
                                        dense
                                        solo
                                        v-model="deposit.sender"
                                        :rules="[rules.required]"
                                        :error-messages="error && error.sender ? error.sender[0] : ''"
                                        :loading="busy"
                                        hide-details="auto"
                                />
                            </template>
                            <p class="body-2 mb-1 white--text">Receiver Number</p>
                            <v-select
                                    v-if="outlet.reqReceiver && outlet.id === 'gcash'"
                                    v-model="deposit.receiver"
                                    background-color="#c0c2ce33"
                                    :items="filterReceivers(outlet.receivers)"
                                    item-text="number"
                                    item-value="number"
                                    :rules="[rules.required]"
                                    :error-messages="error && error.receiver ? error.receiver[0] : ''"
                                    :loading="busy"
                                    solo
                                    dense
                                    hide-details="auto"
                            ></v-select>

                            <v-select
                                    v-else-if="outlet.reqReceiver"
                                    v-model="deposit.receiver"
                                    :items="filterReceivers(outlet.receivers)"
                                    background-color="#c0c2ce33"
                                    item-text="name"
                                    item-value="name"
                                    :rules="[rules.required]"
                                    :error-messages="error && error.receiver ? error.receiver[0] : ''"
                                    :loading="busy"
                                    solo
                                    dense
                                    hide-details="auto"
                            ></v-select>
                            <div class="" v-if="outlet.reqControlNumber">
                                <p class="body-2 mb-1 white--text">
                                    {{ outlet.controlNumberLabel }}
                                </p>
                                <v-text-field
                                        v-model="deposit.reference_number"
                                        single-line
                                        background-color="#c0c2ce33"
                                        dense
                                        solo
                                        :rules="[rules.required]"
                                        :error-messages="
                                            error && error.reference_number
                                            ? error.reference_number[0]
                                            : ''
                                        "
                                        :loading="busy"
                                        hide-details="auto"
                                />
                            </div>
                            <p class="body-2 mb-1 white--text">Notes</p>
                            <v-textarea
                                    auto-grow
                                    rows="1"
                                    v-model="deposit.user_notes"
                                    :loading="busy"
                                    solo
                                    dense
                                    hide-details="auto"
                                    background-color="#c0c2ce33"
                            />
                        </div>
                        <div v-if="!uploadedGcashReceipt">
                            <h3 class="text-center mb-1">Screenshot of the GCash Receipt</h3>
                            <v-file-input
                                    id="imagefile"
                                    v-if="outlet.reqAttachment"
                                    accept="image/*"
                                    label="Choose Receipt"
                                    v-model="deposit.attachment"
                                    :rules="[rules.required]"
                                    :error-messages="
                                        error && error.attachment ? error.attachment[0] : ''
                                    "
                                    :loading="busy"
                                    solo
                                    dense
                                    hide-details="auto"
                                    background-color="#c0c2ce33"
                            />
                        </div>
                    </v-form>
                    <v-btn tile block color="primary" v-if="uploadedGcashReceipt" :loading="busy" @click="submit()"
                    >SUBMIT GCash P2P</v-btn
                    >

                    <v-btn tile block color="primary" v-else :loading="busy" @click="getGcashOcr()"
                    >SUBMIT GCash P2P
                    </v-btn
                    >
                </v-sheet>
                <v-sheet v-else-if="!user.can_deposit"
                         color="#3a4b7b"
                         class="mb-4 pa-3 pb-4  outlet-form">
                    Adding Points has been Disabled. Please contact our CSR.
                </v-sheet>
            </template>
            <!--      <template>-->
            <!--        <v-sheet light class="notes mx-1 mt-1 mb-5 pa-3">-->
            <!--          To buy credits, please contact your main coordinator.-->
            <!--        </v-sheet>-->
            <!--      </template>-->
        </v-container>
        <v-dialog v-model="gatewayModal" max-width="360" >
            <v-card color="#ffffff" dark>
                <v-card-title class="body-2 text-uppercase blue darken-3 py-2 px-3">
                    Confirm
                    <v-spacer></v-spacer>
                    <v-btn icon dark small @click="gatewayModal = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="black--text lighten-1 pt-4">
                    Siguraduhin mayroong sapat na "credits" sa iyong GCash account bago magpatuloy.
                    Maaring abutin ng ilang minuto ang pagproseso.
                </v-card-text>
                <div class="d-flex">
                    <div class="flex-grow-1">
                        <v-btn
                                class="modal-btn no-radius-bottom-right elevation-0"
                                block
                                color="grey lighten-2"
                                light
                                @click="gatewayModal = false"
                        >NO</v-btn
                        >
                    </div>
                    <div class="flex-grow-1">
                        <v-btn
                                class="modal-btn no-radius-bottom-left elevation-0"
                                block
                                color="primary"
                                :disabled="busy"
                                @click="confirmGateway()"
                        >OK</v-btn
                        >
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import mixin from "./../../mixins";
    import rules from "./../../mixins/rules";
    import helpers from "./../../mixins/helpers";

    import PageHeader from "@/sections/PageHeader";

    export default {
        mixins: [mixin, rules, helpers],
        components: {
            PageHeader,
        },

        data: () => ({
            uploadedGcashReceipt: false,
            siAmount: null,
            amountLabel: "Amount",
            showInstruction: true,

            deposit: {
                amount: null,
                outlet: null,
                sender: null,
                receiver: null,
                reference_number: null,
                attachment: null,
                base64: null,
                user_notes: null,
            },
            instructions: {
                gcash: [
                    "Our GCash number change every 10 minutes. Refresh this page before sending.",
                    "Fill the reference no, amount, receiver number in the form below.",
                    "Attach the screenshot receipt in the attachment field.",
                ],
                ml: [
                    "Fill the KPTN, amount, sender, receiver in the form below.",
                    "Attach the receipt in the attachment field.",
                ],
                si: [
                    "Enter amount of credits to transfer from Sabong International below.",
                ],
                cebuana: [],
            },

            outlet: null,
            gateway: null,
            gatewayModal: false,

            valid: true,
            valid2: true,
        }),
        computed: {
            user() {
                return this.$store.state.user;
            },
            outlets() {
                if (this.outletsStore) {
                    return this.outletsStore.outlets.map((outlet) => {
                        if (outlet.id === "si") outlet.name = "Sabong International";
                        return outlet;
                    });
                }
                return [];
            },
            outletsStore() {
                return this.$store.state.outlets.deposits;
            },
            depositAmountRule() {
                if (this.outlet) {
                    let amount = this.deposit.amount;


                    if (this.outlet.id === "si") amount = this.siAmount;
                    return (
                        amount >= parseInt(this.outlet.minimum) ||
                        `Minimum amount is ${this.formatMoney(this.outlet.minimum)}`
                    );
                }
                return true;
            },

            mobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
        },
        methods: {

            setOutlet(select) {
                this.deposit.outlet = select;
                return (this.outlet = this.outlets.find((outlet) => {
                    return outlet.id === select;
                }));
            },
            setDefault() {
                if (this.$route.name === "add-credits" || this.$route.name === "play") {
                    this.deposit.outlet = "gcash";
                } else {
                    this.deposit.outlet = this.$route.params.source;
                }
                if (this.outlets) this.outlet = this.setOutlet(this.deposit.outlet);
            },
            getLogo(outlet) {
                return `${window.SETTINGS.UI_URL}/imgs/${
                    outlet === "si" ? "sabong.png" : outlet + ".jpg"
                }`;
            },
            confirmGateway() {
                this.gatewayModal = false
                if (this.gateway.url) this.openGateway(this.gateway.url)
            },
            openGateway(url) {
                window.open(url, '_system')
            },
            reset() {
                this.$nextTick(() => {
                    if (this.$refs.form) this.$refs.form.reset();
                });
            },
            filterReceivers(receivers) {
                if (receivers && receivers.length) {
                    let newReceivers = [];
                    receivers.forEach((receiver) => {
                        if (receiver.name) newReceivers.push(receiver);
                    });
                    return newReceivers;
                }
                return [];
            },
            submit(game) {
                if (this.$refs.form.validate()) {
                    this.busy = true;
                    let url = `${window.SETTINGS.API_URL}/wallet/deposits`;
                    let data = new FormData();

                    for (let i in this.deposit) {                        
                        if (i == 'attachment') {
                            data.append('attachment_url', this.deposit[i]);
                        } else {
                            data.append(i, this.deposit[i]);
                        }
                    }

                    if (game === "gateway") {
                        url = url + "/redir-gateway";
                        data = {
                            amount: parseFloat(this.deposit.amount),
                            outlet: "mltpy",
                            notes: "",
                        };
                    }

                    window.axios
                        .post(url, data, {
                            "Content-Type": "multipart/form-data",
                        })
                        .then((response) => {
                            if (game === "gateway") {
                                this.gateway = response.data;
                                // this.openGateway(response.data.url)
                                this.gatewayModal = true
                            } else {
                                this.$store.dispatch("prompt", {
                                    message: response.data.message,
                                });
                                this.reset();
                            }
                            this.resetFields();
                            this.uploadedGcashReceipt = false;
                        })
                        .catch((error) => {
                            if (error && error.response)
                                this.error =
                                    error.response.data.errors || error.response.data.message;
                        })
                        .finally(() => {
                            this.busy = false;
                        });
                }
            },
            resetFields() {
                this.deposit.amount = 0;
                this.deposit.reference_number = null;
                this.deposit.receiver = '';
                this.deposit.sender = '';
                this.deposit.user_notes = '';
                this.deposit.attachment = null;
            },
            getGcashOcr() {

                let formData = new FormData();
                formData.append('attachment', this.deposit.attachment);

                let url = '/deposit/gcash/extract-data'

                this.postData(url, formData, response => {
                    window.scrollTo(0, 0);
                    this.deposit.amount = (response.data.gcash_data && response.data.gcash_data.amount) ? response.data.gcash_data.amount : 0;
                    this.deposit.sender = (response.data.gcash_data && response.data.gcash_data.mobile) ? response.data.gcash_data.mobile : null;
                    this.deposit.reference_number = (response.data.gcash_data && response.data.gcash_data.reference) ? response.data.gcash_data.reference : null;
                    this.deposit.attachment = response.data.gcash_receipt ? response.data.gcash_receipt : null;
                    this.uploadedGcashReceipt = true;
                })
            },
            changeReceipt() {
                this.resetFields();
                this.deposit.attachment = null;
                this.uploadedGcashReceipt = false;
            }
        },
        watch: {
            outlets() {
                this.setDefault();
            },
            $route() {
                this.setDefault();
            },
        },
        mounted() {
            this.getOutlets("deposits");
        },
    };
</script>

<style>
    .credits-tab {
        width: 100%;
        margin: 10px;
    }
    .credits-tab.active {
        background-color: #fff;
    }
    .gateway-logo img {
        width: 64px;
        margin: 0 auto;
        border-radius: 4px;
    }
</style>
