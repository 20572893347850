<template>
  <div :class="choiceClass">
    <div class="bet-presets">
      <div class="preset-container" v-if="!addCreditsMode">
        <div
          v-ripple
          @click.stop.prevent="setBetAmount(credits)"
          :class="choiceClass"
          class="preset-btn elevation-1"
        >ALL-IN</div>
      </div>
      <div
        class="preset-container"
        v-for="preset in presets"
        :key="preset"
        v-show="preset <= credits"
      >
        <div
          v-ripple
          @click.stop.prevent="setBetAmount(preset)"
          :class="choiceClass"
          class="preset-btn elevation-1"
        >{{ preset | numeral(0,0) }}</div>
      </div>
      <span class="clear"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["choiceClass", "credits", "addCreditsMode"],
  computed: {
    presets() {
      let preset = [1, 10, 20, 50, 100, 200, 250, 300, 400, 500];
      if (this.credits <= 100) preset = [1, 10, 20, 50, 70, 80];
      if (this.credits > 500 && this.credits <= 1000)
        preset = [100, 200, 300, 400, 500, 600, 800, 1000];
      return preset;
    }
  },
  methods: {
    setBetAmount(amount) {
      this.$emit("set", amount);
    }
  }
};
</script>

<style lang="sass">
.bet-presets
    padding: 4px
    background-color: rgba(0,0,0,.2) !important
    .disabled
        color: #5f5f5f !important
        display: none !important
    .selected
        background-color: orange !important
        color: #333333
    .preset-container
        float: left
        width: 33.3333333333333%
        overflow: hidden
        white-space: nowrap
        .preset-btn
            margin: 4px
            text-align: center
            background: #292929
            color: #dadada
            padding: .4em 1px
            white-space: nowrap

.add-credits-section
  .bet-presets
    padding: .3em
    background-color: rgba(255, 255, 255, .7) !important

@media screen and (max-width: 374px)
    .bet-presets
        .preset-container
            .preset-btn
                font-size: .86em
</style>