<template>
  <v-app style="background:#2E3249 !important;">
    <v-main :class="{ 'fab-bottom-padding': inApp, 'page-content': subAppView }">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
      <main-tool-bar v-if="inApp" :user="user"/>
      <snackbar />
      <win-overlay />
      <prompt />
    </v-main>
    
    <QRCode />
    <CommissionModal />
    <overlay />
  </v-app>
</template>

<script>
import routes from "./mixins/routes";
import app from "./mixins/app";
import pusher from "./mixins/pusher";
import data from "./mixins/data";
import fetchers from "./mixins/fetchers";

import Snackbar from "./components/Snackbar.vue";
import Overlay from "./components/Overlay.vue";
import WinOverlay from "./components/WinOverlay.vue";
import Prompt from "./modals/Prompt.vue";
import MainToolBar from "@/components/MainToolBar";
import QRCode from './modals/QRCode.vue';
import CommissionModal from './modals/CommissionModal.vue';

export default {
  mixins: [routes, app, pusher, data, fetchers],
  components: {
    CommissionModal,
    QRCode,
    Snackbar,
    Overlay,
    WinOverlay,
    Prompt,
    MainToolBar,
  },
};
</script>

<style lang="sass">
@import './sass/colors.scss'

.height-100
  min-height: 100vh


.button-meron
  background-color:$side-red-color

.side-title-meron
  background: $side-red-color
  font-size: .9em
  margin-bottom: 1em
  text-transform: uppercase
  font-weight: 800
  font-size: 1.3em
  font-family: Montserrat,sans-serif


.side-title-wala
  background: $side-blue-color
  font-size: .9em
  margin-bottom: 1em
  text-transform: uppercase
  font-weight: 800
  font-size: 1.3em
  font-family: Montserrat,sans-serif


.side-title-draw
  background: $side-jackpot-color
  font-size: .9em
  margin-bottom: 1em
  text-transform: uppercase
  font-weight: 800
  font-size: 1.3em
  font-family: Montserrat,sans-serif



.bg-success
  background-color: #009900

.bg-danger
  background-color: #db5b5b

.bg-warning
  background-color: #ed9c4a

.bg-info
  background-color: #2f80d8


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  apperance: none
  margin: 0

h3
  font-weight: 400
  font-size: 1.1em

.main-snackbar
  // margin-top: 48px !important

.circle
  height: 12px
  width: 12px
  background-color: #272727
  border-radius: 50%
  display: inline-block

.page-content
  background: #2e3448

.text-red
  color: $side-red-color

.text-blue
  color: $side-blue-color

.text-jackpot
  color: $side-jackpot-color


button.v-btn
    &.text-meron
        color: #fff


button.v-btn
    &.text-wala
        color: #fff

button.v-btn
    &.text-draw
        color: #fff



.red-empty
  border: 2px solid $side-red-color

.blue-empty
  border: 2px solid $side-blue-color

.jackpot-empty
  border: 2px solid $side-jackpot-color


.meron-empty
  border: 2px solid $side-red-color
  background-color: #272c38

.wala-empty
  border: 2px solid $side-blue-color
  background-color: #272c38

.draw-empty
  border: 2px solid $side-jackpot-color
  background-color: #272c38



.text-lose
  color: #828282

.clear
  display: block
  float: none
  clear: both

.p-relative
  position: relative !important

.no-underline
  text-decoration: none !important

.fab-bottom-padding
  padding-bottom: 60px !important

.v-speed-dial__list
  padding: 0 6px !important
  .v-btn
    border: 2px solid orange

.fab-btn
  overflow: hidden
  img, .v-btn__content
    width: 100% !important

//Auth: Login, Registration, Verify, Password Reset

.main-form
    // margin: 0 auto
    width: 100%
    max-width: 300px
    // padding-right: 30px
    // padding-left: 30px
      font-weight: 400

.reg-prompt-btn
  width: 100%
  text-align: right

.login-container , .register-container, .forgot-pass-cont
    background: linear-gradient(200deg, #1a2136, #000000)

.login-logo
  max-width: 280px
  margin-right:30px
  // margin: 0 auto 10px

.auth-note
  font-size: .85em !important
  color: gray

.reg-logo
  max-width: 120px !important
  margin: 0 auto 10px

.terms
  font-size: .9em
  h3
    margin-bottom: 1em
    font-weight: 600

.wala-text
    color: #2f80d8 

.meron-text
    color: #ff5252

.draw-text
    color: #ed9c4a

.terms-link
  color: #b5b5b5 !important

.error--text
  .terms-link
    color: #ff5252 !important

//Wallet: Deposit, Withdraw

.outlet-details
  font-size: .96em
  line-height: 1.5em
  h3
    font-weight: 600
  .phone-number
    letter-spacing: 0.1em
  .amount
    color: #ffca34 !important
    display: inline
  .outlet-icon
    width: 64px
    height: 56px
    margin: -40px auto 16px auto
    border-radius: 4px
    overflow: hidden
    img
      width: 100%
  .small
    font-size: .92em
  .instructions
    font-size: .92em
    border-bottom: 1px solid rgba(255,255,255,0.3)
    padding: 1em 0 1em
  .instructions:first-child
    padding-top: 0 !important
  .instructions:last-child
    padding-bottom: 0
    border-bottom: none

.notes
  background: #5a658a !important
  color: #ffffff !important
  font-size: 0.9em


.wallet-form
  .v-input
    margin-bottom: .8em !important

.list-history
  font-size: 1em
  border-bottom: 1px solid #292d3c
  .with-amount
    font-size: 1em
  .with-status
    font-size: .8em
  .with-info
    font-size: .8em
  .dep-info
    opacity: .8
  a
    text-decoration: none

.cancelled-status
  color: red

.processing-status, .refunded-status
  color: #FFB300

.paid-status, .completed-status
  color: #7CB342


.acc-devider
  border-bottom: 1px solid #2e3448
  border-top: 1px solid #0d111f


.cash-in-cont .v-skeleton-loader__list-item-three-line, .transaction-container .v-skeleton-loader__list-item-three-line
    background: #282e42 !important



    /* width */
#test ::-webkit-scrollbar 
    height: 5px

/* Track */
#test ::-webkit-scrollbar-track 
    background-color: #242d43
 
/* Handle */
#test ::-webkit-scrollbar-thumb 
    background: #597aaa
    border-radius: 10px
/* Handle on hover */
#test ::-webkit-scrollbar-thumb:hover 
    background: #668bc1

.sticky-bottom
    position: sticky
    bottom: 5px

.heart 
     animation: 1s infinite beatHeart

@keyframes beatHeart 
  0% 
    transform: scale(1)
  
  25% 
    transform: scale(1.1)
  
  40% 
    transform: scale(1)
  
  60% 
    transform: scale(1.1)
  
  100% 
    transform: scale(1)
  


@media (max-width: 768px)
    .account-cont
        margin:15px !important

@media (max-width: 425px)
    .login-logo
        max-width: 200px
        margin-bottom:30px
        margin-right:0
    .play-icon
        font-size:35px

    .main-form
        max-width: 100%

    

@media (max-width: 374px)
    button.v-btn.bet-btn
        min-width: 100px !important
    .adv-side
        width: 46px !important

    .adv-num
        min-width: 20px  !important
        font-size: 10px  !important

    .adv-remove
        font-size: 14px !important
    .adv-amount
        min-width: 75px !important
        padding: 0 4px !important
    
    .sides
        
        .side-section
            justify-content: center !important
            align-items: center !important

            
            .btn-content
                padding: 0 !important
                
                button
                    margin-top:0 !important
            .side-title-meron, .side-title-wala, .side-title-draw
                margin-bottom: 0 !important
                padding: 8px 4px !important
                width: 70% !important
                font-size: 1.1rem !important
</style>
