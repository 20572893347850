<template>
    <div class="">
    <v-row class="height-100 d-flex flex-column justify-center" no-gutters>
      <v-col cols="12" class="text-center ">
        <h1 class="mb-0 font-weight-bold white--text" style="font-size:55px; ">
          4<span>
            <v-icon size="75" color="white " class="">
              mdi-emoticon-sad-outline
            </v-icon> </span
          >4
        </h1>
        <p class="grey--text">Page not Found</p>

        <v-btn  color="warning" depressed @click="goTo('/login')">
          Go back Home
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    goTo(path, query = {}) {
      this.$router.push({ path: path, query: query }).catch(() => {
        this.drawer = false
      })
    }
  }
}
</script>
