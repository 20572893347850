<template>
  <div class="account-cont" >
        <credit-balance></credit-balance>
        <div class="mt-3">
        <account-settings @logout="logout" />

        <!-- <v-btn v-show="user.type !== 'player' && user.inv_link" block class="text-capitalize" color="#fb8c00" @click.stop="showCommissionModal()" depressed tile>
                View commission earnings here!
        </v-btn> -->
        <br>
        <!-- <invite-friend></invite-friend> -->
        </div>
  </div>
</template>

<script>
import mixins from "@/mixins";
import list from "@/mixins/list";
import helpers from "@/mixins/helpers";

// import InviteFriend from "@/components/InviteFriend";
import CreditBalance from "@/components/CreditBalance";
import AccountSettings from "@/components/AccountSettings";

export default {
  mixins: [mixins, list, helpers, global],
  components: {
    CreditBalance,
    AccountSettings,
    // InviteFriend
  },
  data: () => ({
    tab: 0,
    show1: false,
    valid: true,
    username: null,
    password: "Password",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  methods: {
    showCommissionModal() {
      this.$store.dispatch("showCommissionModal");
    },

    setTab(tab) {
      this.tab = tab;
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      // localStorage.removeItem('switcher_on_boot')
      window.axios.defaults.headers.common["Authorization"] = null;
      this.$router.push({ name: `login` });
    },
  },
};
</script>

<style scoped>

.sticky-cont{
    position: sticky;
    top: 0;
 
}
.account-tab {
  background-color: #2c3439;
  color: #fff;
}

.account-tab.active {
  background-color: #fff;
  color: #000;
}
</style>
