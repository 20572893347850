export default {
    methods: {
        bootGame() {
            this.$nextTick(() => {
                this.getClients()
            })
        },
        getClients() {
            this.getData(`/clients`, response => {
                this.$store.dispatch('setClients', response.data)
                this.getGame()
                this.getTrends()
                this.getHistory()
            })
        },
        onBetSubmit() {
            console.log('bet submitted')
        },
        getGame(resume = false) {
            let clientBrowser = window.checkBrowser()
            this.getData(`/${window.SETTINGS.API_SLUG}/games?type=${clientBrowser.drmType}`, response => {
                this.$store.dispatch('setGame', response.game[0])
                if(response.client) this.$store.dispatch('setClient', response.client)
                if ((response.game[0] && response.game[0].winner) || resume) {
                    this.getUserProfile()
                    this.getTrends()
                }
            })
        },
        getTrends(delay = 1200) {
            setTimeout(() => {
                this.getData(`/${window.SETTINGS.API_SLUG}/trends`, response => {
                    this.$store.dispatch('setTrends', response)
                })
            }, delay)
        },
        getHistory(page = 1, delay = 1000, newData = false) {
            setTimeout(() => {
                this.getData(`/${window.SETTINGS.API_SLUG}/bets?limit=10&page=${page}`, response => {
                    if (newData) response['new_data'] = true
                    this.$store.dispatch('setHistory', response)
                })
            }, delay)
        },
        getRandomHistory() {
            let delay = ((Math.random() * (4 - 1 + 1)) << 0) * 1000
            console.log('get history delay: ' + delay)
            this.getHistory(1, delay, true)
        },
        getUserProfile(callback) {
            this.getData(`/${window.SETTINGS.API_SLUG}/account`, response => {
                let data = response.data
                this.$store.dispatch('setUser', data)
                localStorage.setItem('user', JSON.stringify(data))
                if (callback) callback()
            }, () => {
                window.location.reload()
            })
        },
        getOutlets(mode) {
            this.getData(`/wallet/${mode}/outlets`, response => {
                response['mode'] = mode
                this.$store.dispatch('setOutlets', response)
            })
        },
        getWalletHistory(mode, page = 1, newData = false) {
            this.getData(`/wallet/${mode}?limit=2&page=${page}`, response => {
                response['mode'] = mode
                if (newData) response['new_data'] = true
                this.$store.dispatch('setWallet', response)
            })
        }
    }
}