import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from './App.vue'
import VueNumeralFilter from 'vue-numeral-filter'
import { sync } from 'vuex-router-sync'
import router from './router'
import store from './store'
import axios from 'axios'

//Set Vue
sync(store, router)
Vue.use(VueNumeralFilter)

if (window.SETTINGS.ENVIRONMENT === 'production') {
  Vue.config.devtools = false
  Vue.config.debug = false
  Vue.config.silent = true
} else {
  Vue.config.devtools = true
  Vue.config.debug = true
  Vue.config.silent = false
  Vue.config.productionTip = true
}

// Axios set
window.axios = axios
axios.defaults.timeout = window.SETTINGS.REQUEST_TIMEOUT
axios.defaults.headers.common['Content-Type'] = 'application/json'

//Set api slug
let slug = localStorage.getItem('slug')
if (slug && slug !== 'undefined') window.SETTINGS.API_SLUG = slug
console.log(slug)

//Boot app
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

