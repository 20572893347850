<template>
  <v-container class="fill-height forgot-pass-cont" fluid>
    <v-row>
      <v-col cols="12 " class=" mx-auto ">
        <div class="d-flex flex-column flex-sm-row align-center justify-center">

            <div class="mr-6">
                  <img src="./../assets/ufc-logo.png" class="login-logo" />
                </div>
          <div class="">
            <v-form
              v-if="!submitted"
              ref="form"
              v-model="valid1"
              lazy-validation
              class="main-form"
              @submit="requestReset()"
            >
              <div class="mb-4">
                
                <h4>Forgot Password</h4>
                <p class="mt-4 auth-note">
                  Reset your account's password by providing the mobile number
                  associated with your account.
                </p>
              </div>
              <p class="body-2 my-2 white--text">Mobile Number</p>
              <v-text-field
                v-model="request.mobile"
                type="number"
                :rules="[rules.required, rules.phone]"
                :error-messages="error && error.mobile ? error.mobile[0] : ''"
                :loading="busy"
                @keypress.enter="requestReset()"
                required
                background-color="#c0c2ce33"
                solo
                dense
                hide-details="auto"
              ></v-text-field>
              <div class="text-center mt-4">
                <v-btn
                  color="warning"
                  class="mb-5"
                  @click.stop="requestReset()"
                  :loading="busy"
                  block
                  >Submit</v-btn
                >
                <v-btn
                  color="white"
                  outlined
                  block
                  @click.stop="$router.push({ name: 'login' })"
                  >Back to login</v-btn
                >
              </div>
            </v-form>
            <v-form
              v-else
              ref="resetForm"
              v-model="valid2"
              lazy-validation
              class="main-form"
              @submit="resetPassword()"
            >
              <div class="text-center mb-4">
                <h4>Reset Password</h4>
                <p class="mt-4 auth-note">
                  We sent a One Time Pin to
                  <strong> {{ credentials.mobile }} </strong>. Please use the
                  pin to reset your password.
                </p>
              </div>
              <p class="body-2 my-2 white--text">One Time Pin</p>
              <v-text-field
                v-model="credentials.pin"
                type="number"
                :rules="[rules.required]"
                :error-messages="error && error.pin ? error.pin[0] : ''"
                :loading="busy"
                required
                background-color="#c0c2ce33"
                solo
                dense
                hide-details="auto"
              ></v-text-field>
              <p class="body-2 my-2 white--text">Password</p>
              <v-text-field
                v-model="credentials.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                :loading="busy"
                :rules="[rules.required, rules.password]"
                :error-messages="
                  error && error.password ? error.password[0] : ''
                "
                required
                background-color="#c0c2ce33"
                solo
                dense
                hide-details="auto"
              ></v-text-field>
              <p class="body-2 my-2 white--text">Confirm Password</p>
              <v-text-field
                v-model="credentials.password_confirmation"
                label="Confirm Password"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
                :loading="busy"
                :rules="[
                  rules.required,
                  rules.password,
                  passwordConfirmationRule,
                ]"
                :error-messages="
                  error && error.password_confirmation
                    ? error.password_confirmation[0]
                    : ''
                "
                @keypress.enter="resetPassword()"
                required
                background-color="#c0c2ce33"
                solo
                dense
                hide-details="auto"
              ></v-text-field>
              <div class="text-center mt-4">
                <v-btn
                  color="warning"
                  class="mb-5"
                  @click.stop="resetPassword()"
                  :loading="busy"
                  block
                >
                  RESET
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click.stop="$router.push({ name: 'login' })"
                >
                  Back to login
                </v-btn>
              </div>
            </v-form>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import auth from "./../mixins/auth";
import rules from "./../mixins/rules";

export default {
  mixins: [mixin, auth, rules],
  data: () => ({
    valid1: true,
    valid2: true,
    submitted: false,
    show1: false,
    show2: false,
    request: {
      mobile: null,
    },
    credentials: {
      mobile: null,
      pin: null,
      password: null,
      password_confirmation: null,
    },
  }),
  computed: {
    passwordConfirmationRule() {
      return (
        this.credentials.password === this.credentials.password_confirmation ||
        "Password must match"
      );
    },
  },
  methods: {
    requestReset() {
      if (this.$refs.form.validate()) {
        this.busy = true;
        window.axios
          .post(`${window.SETTINGS.API_URL}/auth/reset/request`, this.request)
          .then(() => {
            this.submitted = true;
          })
          .catch((error) => {
            if (error && error.response)
              this.error =
                error.response.data.errors || error.response.data.message;
          })
          .finally(() => {
            this.busy = false;
          });
      }
    },
    resetPassword() {
      if (this.$refs.resetForm.validate()) {
        this.busy = true;
        this.credentials.mobile = this.request.mobile;
        window.axios
          .post(`${window.SETTINGS.API_URL}/auth/reset`, this.credentials)
          .then(() => {
            this.$store.dispatch("snackbar", {
              message: "Password updated successfully",
            });
            this.$router.push({ name: "play" });
          })
          .catch((error) => {
            if (error && error.response)
              this.error =
                error.response.data.errors || error.response.data.message;
          })
          .finally(() => {
            this.busy = false;
          });
      }
    },
  },
};
</script>
