<template>
  <div class="side-section" :class="betSection">
    <div :class="sideTitle" v-html="sideName"></div>
    <div class="side-amount h5 mb-0" :class="counterClass">
      <span v-html="formatBetAmount(side.amount)"></span>
      <div class="odds">
        <strong>{{ multi | numeral(0, 0) }}%</strong>
      </div>
    </div>
    <div class="btn-content">
      <v-btn
        tile
        small
        light
        block
        class="mt-2"
        v-if="user.credits == 0"
        disabled
      >
        No Credits
      </v-btn>
      <v-btn
        class="bet-btn"
        :color = "sideButtonColor"
        small
        block
        tile
        :class="textClass"
        v-else-if="canBet"
        @click.stop="$emit('bet', modalData)"
      >
        BET {{ name }}
      </v-btn>
      <v-btn
        class="bet-btn"
        small
        color="#fff"
        block
        tile
        :class="textClass"
        v-else
        disabled
      >
        {{ status }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import helpers from "./../mixins/helpers";

export default {
  mixins: [helpers],
  props: ["name", "side", "game", "user", "status"],
  data: () => ({
    counterClass: [],
    counterTm: null,
  }),
  computed: {
    multi() {
      return this.side ? this.formatMoney(this.side.multi * 100) : "";
    },
    sideButtonColor()
    {
      var color = '';
      if(this.name == 'meron')
      {
        return '#f34141';
      }
      if(this.name == 'wala')
      {
        return '#1e81f1';
      }
      if(this.name == 'draw')
      {
        return '#009900';
      }
      return color;
    },
    betSection()
    {
      return 'background: #2e3249 !important'
    },
    sideTitle()
    {
      return `side-title-${this.name} py-2 align-center`;
    },
    sideName() {
      return `${this.name}`;
    },
    textClass() {
      return `text-${this.name}`;
    },
    sectionClass() {
      return `${this.name}-section`;
    },
    canBet() {
      return this.game.status === 1 || this.game.status === 2;
    },
    modalData() {
      return {
        name: this.name,
        side: this.side.side,
        class: this.sectionClass,
      };
    },
  },
  methods: {
    formatBetAmount(amount) {
      if (!amount) return 0;
      let number = this.formatMoney(amount);
      number = number.split(".");
      return `${number[0]}<span class="decimal">.${number[1]}</span>`;
    },
  },
  watch: {
    "side.amount"() {
      this.counterClass = ["animated", "heartBeat"];
      if (this.counterTm == null) {
        this.counterTm = setTimeout(() => {
          this.counterClass = [];
          clearTimeout(this.counterTm);
        }, 2000);
      }
    },
  },
};
</script>
