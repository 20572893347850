<template>
  <div class="">
    <v-container class="pa-0 ma-0 cash-in-cont" fluid>
      <page-header title="Transactions" back-button="account" v-if="mobile" />
      <div v-if="hasList">
        <div class="mb-3 ">
          <div
            class="d-flex pa-3 justify-space-between transaction-container"
            v-for="(transaction, index) in lists"
            :key="index"
          >
            <span>
              <p
                class="mb-0 body-1 font-weight-bold yellow--text text--darken-3"
              >
                {{ formatMoney(parseFloat(transaction.amount)) }}
              </p>
              <p class="mb-0 body-2">
                {{ transaction.player_type_label }} -
                <span class="caption "
                  >{{ transaction.date_label }}
                  {{ transaction.time_label }}</span
                >
              </p>
            </span>
            <span class="text-right">
              <template
                class=""
                v-if="transaction.user1 && transaction.user1.masked_email"
              >
                <p class="mb-0 body-2">{{ transaction.user1.masked_email }}</p>
                <p class="mb-0 body-2">{{ transaction.user1.name }}</p>
              </template>

              <template v-else >
                  
                <p class="mb-0 body-2">
                  System
                </p>
                <v-chip x-small :color="outletColor(transaction.remarks)" v-if="transaction.remarks">
                  {{ transaction.remarks }}
                </v-chip>
              </template>
            </span>
          </div>
        </div>
      </div>
      <div v-else-if="lists < 1" class="text-center text-lose mb-3">
        <div class="" v-if="loading">
          <v-skeleton-loader type="list-item-three-line"> </v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"> </v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"> </v-skeleton-loader>
        </div>
        <div v-else>
          <p class="mb-0 mt-3 body-1">You have no transactions yet</p>
        </div>
      </div>
      <div v-if="hasMore" class="my-3 text-center">
        <v-btn text small :loading="loading" @click.stop.prevent="loadMore()">
          Show More
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import mixins from "./../../mixins";
import list from "./../../mixins/list";
import helpers from "./../../mixins/helpers";

import PageHeader from "@/sections/PageHeader";

export default {
  mixins: [mixins, list, helpers],
  data: () => ({
    dataUrl: `/wallet/transactions`,
  }),
  components: {
    PageHeader,
  },
  methods: {
    outletColor(outlet) {
      if (outlet === "Ml") {
        return "red lighten-1";
      }
      if (outlet === "Gcash") {
        return "light-blue darken-3";
      }
      if (outlet === "Cebuana") {
        return "cyan darken-3";
      }
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>
<style lang="sass" scoped>
.transaction-container
    border-bottom: 2px solid #282E40
    margin-bottom: 2px
</style>
