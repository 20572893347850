<template>
	<div>
		<v-sheet v-if="client.settings.announcement" color="#B31013" class="announcement  text-uppercase mb-1 mt-1 font-weight-bold"> 
			<marquee direction = "left" class="mt-2">{{ client.settings.announcement }}</marquee>
		</v-sheet>
	</div>
</template>

<script>
export default {
   props: ['client']
}
</script>

<style lang="sass">

.draw-text-container 
  background: #2d2d2d

.announcement
    font-size: .9em

@media (max-width: 374px)
    .announcement
        font-size: .8em !important
</style>