<template>
    <v-container class="play-page" fluid v-if="loggedIn && user">
        <v-row>
            <v-col cols="" lg="8" md="8" sm="12" xs="12" class="main-play-page">
                <channel
                        v-if="game"
                        :game="game"
                        :loading="loading"
                        :status-label="gameStatus[game.status]"
                />
                <!-- <v-btn  v-if="!mobile"  v-show="user.inv_link" block class="text-capitalize" color="primary" @click="goTo('/invite')" depressed tile>
                      Invite your friends and earn!
                </v-btn> -->

                <fight-description
                        :client="client"
                />
                <div class="p-relative">
                    <player-vjs
                            :client="client"
                            :cant-stream="user.is_stream_blocked"
                            :userID="user.id"
                    />
                </div>
                <announcement :client="client"/>
                <v-col v-for="(popPromos,index) in popPromo" :key="index" class="d-flex child-flex" sm="12" md="6"
                       lg="4" cols="12">
                    <v-overlay :value="overlay" v-if="show_promo === 'true'">
                        <div style="max-width: 750px;" class="text-center">
                            <img :src="popPromos" alt="" style="height: 80vh; width: 95%; " contain
                                 @click.stop="popPromos = null ||(overlay = false) || (overlay= null)">
                                 <div style="width:95%; margin: auto; margin-top: -7px;">
                                    <v-btn
                                        @click.stop="popPromos = null ||(overlay = false) || (overlay= null)"
                                        small
                                        block
                                        tile>Close
                                    </v-btn>
                                 </div>
                        </div>
                    </v-overlay>
                </v-col>
                <bet-section
                        :user="user"
                        :game="game"
                        :side-colors="sideColors"
                        :status-label="gameStatus[game.status]"
                        :submit-callback="onBetSubmit"
                />
                <credits v-if="!mobile"/>
                <v-row class="mx-0" id="test">
                    <v-col cols="12" :class="{ webview: mobile }">
                        <p class="body-2 my-2">TRENDS</p>
                        <trends/>

                        <div class="mt-2">
                            <bet-history :side-colors="sideColors" :load-more="getHistory"/>

                            <v-btn
                                    v-if="!mobile"
                                    block
                                    class="mt-3 text-capitalize"
                                    @click="goTo('/help')"
                                    depressed
                                    tile
                                    color="white"
                                    outlined
                            >
                                <v-icon class="mr-1">mdi-message-text</v-icon>
                                Rules
                            </v-btn>

                            <v-btn
                                    v-if="!mobile"
                                    block
                                    class="mt-3 text-capitalize"
                                    color="#ff5100"
                                    @click="openHelp()"
                                    depressed
                                    tile
                            >
                                <v-icon class="mr-1">mdi-message-text-outline</v-icon>
                                Contact Customer Support
                            </v-btn>
                            <!--
                                          <v-btn v-if="user.inv_link" block class="mt-3 text-capitalize" color="primary" @click.stop="showQR()" depressed tile>
                                                  Invite your friends and earn!
                                          </v-btn>
                             -->
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="" lg="4" md="4" sm="12" xs="12" v-if="mobile" class="pl-2">
                <account/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import mixin from "./../mixins";
    import gtag from "./../mixins/gtag";
    import PlayerVjs from "./../sections/PlayerVJS";
    import Channel from "./../sections/Channel";
    import FightDescription from "./../sections/FightDescription";
    import Announcement from "./../sections/Announcement";
    import BetSection from "./../sections/BetSection";
    import BetHistory from "./../sections/BetHistory";
    import Trends from "./../sections/Trends";
    import Account from "@/views/Account";
    import Credits from "@/components/Credits";


    export default {
        mixins: [mixin, gtag],
        data: () => ({
            actionSub: null,
            showSwitcher: window.SETTINGS.SHOW_SWITCHER,
            promos: [],
            popPromo: [],
            overlay: true,
            show_promo: false

        }),
        computed: {
            isIOS() {
                return window.isIOS;
            },
            mobile() {
                return this.$vuetify.breakpoint.mdAndUp;
            },
        },
        methods: {
            activityChecker() {
                let allowedActiveMins = Math.floor(Math.random() * 5) + 10;
                let initTs = Date.now();
                // let interval = setInterval(() => {
                setInterval(() => {
                    let currentTs = Date.now();
                    let diffMs = currentTs - initTs;
                    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

                    if (diffMins > allowedActiveMins) {
                        window.location.reload();
                    }
                }, 1000);
            },
            showQR() {
                this.$store.dispatch("showQRModal");
            },

            openHelp() {
                window.open(window.SETTINGS.LC_URL, "_system");
            },

            goTo(path) {
                this.$router.push({path: path}).catch(() => {
                    this.drawer = false;
                });
            },
        },
        components: {
            PlayerVjs,
            Channel,
            FightDescription,
            Announcement,
            BetSection,
            Trends,
            BetHistory,
            Credits,
            Account,

        },
        mounted() {
            this.show_promo = localStorage.getItem('show_modal')
            this.$nextTick(() => {
                this.bootGame();
                if (this.user.is_si_player && this.user.credits < 1)
                    this.$store.dispatch("showWalletModal");
            });
            this.actionSub = this.$store.subscribeAction((action) => {
                if (action.type === "getHistory") this.getHistory(1, 500, true);
                if (action.type === "getRandomHistory") this.getRandomHistory();
                if (action.type === "getTrends") this.getTrends();
            });
            this.activityChecker();

            if (this.show_promo) {
                this.getData('/promos',
                    response => {
                        this.popPromo = response.popPromo;
                        localStorage.setItem('show_modal', false)
                    },
                    () => {
                        this.redirectTo404()
                    }
                )

            }

        },

        beforeDestroy() {
            this.actionSub();
        },
    };
</script>

<style lang="sass">
    .webview
        background: rgb(49, 55, 81)
        background: linear-gradient(180deg, rgba(49, 55, 81, 1) 0%, rgba(49, 55, 81, 1) 75%, rgba(46, 49, 72, 1) 100%)

    .play-page
        max-width: 1024px

        h4
            font-weight: 500

    .main-player
        width: 100%
        height: 0
        padding: 0 0 56.25% 0
        background-color: #000000

    .account-tab
        background-color: #242631 !important
        color: #fff

    .account-tab.active
        background-color: #ed9c4a !important
        color: #fff

    .devider-top
        border-color: #191C2E !important

    .devider-bot
        border-color: #424769 !important

    .sheet-form
        background-color: #2e3548 !important

    .main-play-page
        padding: 10px

    @media (max-width: 768px)
        .main-play-page
            padding: 0 !important
        .play-page
            padding-top: 0 !important

    @media (max-width: 375px)

        .play-icon
            font-size: 60px !important

    @media (max-width: 320px)
        .chan-cont h5.body-2
            font-size: 12px !important
        p.logo-text-top
            font-size: 17px !important

        p.logo-text-bot
            font-size: 11px !important
</style>
