<template>
  <div class="play-page px-2">
    <div class="text-center"><h3 class="mb-1 mt-1">Advance Bets</h3></div>
    <div v-if="hasList" class="mt-4">
      <div class="row">
        <bet-list
          v-for="(bet, index) in lists"
          :key="index"
          :num="bet.sequence"
          :side="sideColors[bet.side - 1]"
          :amount="formatMoney(bet.amount)"
        >
        </bet-list>
      </div>
    </div>
    <div v-else-if="lists < 1" class="text-center text-lose mb-3">
      <small>{{
        loading ? "Loading..." : "You have no advance bets yet"
      }}</small>
    </div>
    <div v-if="hasMore" class="my-3 text-center">
      <v-btn text small :loading="loading" @click.stop.prevent="loadMore()">
        More <v-icon size="14" class="ml-1">mdi-chevron-down</v-icon></v-btn>
    </div>
  </div>
</template>

<script>
import list from "./../mixins/list";
import helpers from "./../mixins/helpers";

export default {
  mixins: [list, helpers],
  props: ["sideColors"],
  data: () => ({
    dataUrl: `/${window.SETTINGS.API_SLUG}/advance-bets`,
    limit: 100,
    actionSub: null,
  }),
  components: {
    BetList: () => import("./../components/BetList"),
  },
  mounted() {
    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type === "appEvent") {
        this.getList((this.listPage = 1));
      }
    });
  },
  watch: {
    "listData.data"() {
      if (this.listData.data) {
        let list = this.listData.data.slice(-1).pop();
        this.$emit("last", list);
        this.$emit("total", this.listData.data.length)
      }
    },
  },
  beforeDestroy() {
    this.actionSub();
  },
};
</script>
