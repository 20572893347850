<template>
  <v-bottom-navigation
    :value="value"
    color="#1b1f38"
    grow
    class="maintoolbar"
    v-if="mobile"
  >
    <!-- <v-btn class="white--text" v-if="user.type !== 'player' && user.inv_link"  @click="goTo('/commission')">
      <span>COMMISSIONS</span>
      <v-icon>mdi-cash-multiple</v-icon>
    </v-btn> -->

    <v-btn class="white--text" @click="goTo('/help')">
      <span>Rules</span>
      <v-icon>mdi-message-text</v-icon>
    </v-btn>

    <v-btn class="white--text" @click="goTo('/')">
      <span>PLAY</span>
      <v-icon>mdi-play-circle</v-icon>
    </v-btn>

    <v-btn class="white--text" @click="goTo('/account')">
      <span>ACCOUNT</span>
      <v-icon>mdi-account</v-icon>
    </v-btn>
    
  </v-bottom-navigation>
</template>
<script>
export default {
  props:['user'],
  data: () => ({ value: 1 }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    goTo(path) {
      this.$router.push({ path: path }).catch(() => {
        this.drawer = false;
      });
    },
    logout() {
      this.drawer = false;
      this.$emit("logout");
    },
    reload() {
      window.location.reload();
    },
  },
};
</script>

<style>
.maintoolbar {
  color: #fff;
  background: #0e1429 !important;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  position: fixed;
  bottom: 0;
}
</style>
