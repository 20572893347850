<template>
  <div>
    <div class="sides" v-if="game">
      <bet-side
        v-for="(color, index) in sideColors"
        :key="index"
        :name="sideColors[index]"
        :side="game.bets[index]"
        :game="game"
        :user="user"
        :status="statusLabel"
        @bet="setChoice"
      />
      <div
        class="side-win"
        v-if="game.winner !== null"
        :class="sideColors[game.winner - 1] + '-section'"
      >
        <div class="win-content text-center">
          Fight #{{ game.game_num }} Result<br />
          <strong style="font-size: 1.4em">{{
            sideColors[game.winner - 1]
          }}</strong>
        </div>
      </div>
      <div class="side-win cancelled-section" v-else-if="game.status === 5">
        FIGHT CANCELLED
      </div>
      <v-overlay :value="user.is_blocked" absolute opacity="1"
        >You are not allowed to bet</v-overlay
      >
    </div>
    <v-dialog light class="bet-modal" v-model="openModal" max-width="300">
      <v-card v-if="choice">
        <div
          class="bet-modal-header d-flex align-center py-2 px-3 elevation-0"
          :class="choice.class"
        >
          <strong> Bet {{ choice.name }} </strong>
          <v-spacer></v-spacer>
          <v-btn small icon dark>
            <v-icon size="20" @click="openModal = false">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <div
          v-if="confirm && bet.amount && openModal"
          style="color: rgba(0, 0, 0, .87)"
        >
          <v-card-text class="pa-3 py-5 text-center">
            <div v-if="busy" class="px-2 pb-3 pt-2">
              Submitting...
              <br />
              <v-progress-circular
                class="loading-circle mt-2"
                size="36"
                indeterminate
                color="orange"
              />
            </div>
            <div v-else-if="canBet">
              Are you sure you want to bet
              <br />
              <h3 class="mt-1 text--white">
                <strong>{{ formatMoney(bet.amount) }}</strong> on
                <span class="text-uppercase"
                  ><strong>{{ choice.name }}</strong></span
                >?
              </h3>
            </div>
            <div v-else>{{ gameStatusMessage }}</div>
          </v-card-text>
          <div class="d-flex" v-if="!busy">
            <template v-if="canBet">
              <div class="flex-grow-1">
                <v-btn
                  class="modal-btn no-radius-bottom-right elevation-0"
                  block
                  color="grey lighten-2"
                  light
                  @click="openModal = false"
                  >NO</v-btn
                >
              </div>
              <div class="flex-grow-1">
                <v-btn
                  class="modal-btn no-radius-bottom-left elevation-0"
                  block
                  :class="choice.class"
                  :loading="busy"
                  @click="submit()"
                  >YES</v-btn
                >
              </div>
            </template>
            <template v-else>
              <v-btn class="modal-btn" block light @click="openModal = false">
                OK
              </v-btn>
            </template>
          </div>
        </div>
        <template v-else>
          <v-card-text class="pa-2">
            <v-form
              ref="betForm"
              v-model="valid"
              lazy-validation
              @submit="submitBet()"
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                ref="betValue"
                v-model="bet.amount"
                :min="1"
                :label="label"
                :placeholder="placeholder"
                type="number"
                :rules="[rules.amountRule, rules.minAmountRule, creditsRule]"
                :error="!!error"
                :error-messages="error"
                :hint="hint"
                :disabled="busy"
                @focus="
                  label = 'Amount';
                  placeholder = '';
                "
                @blur="
                  bet.amount === null ? (label = 'Amount') : (label = '');
                  placeholder = defaultPlaceholder;
                "
                @keyup.enter="submitBet()"
                required
                clearable
              ></v-text-field>
            </v-form>
          </v-card-text>
          <bet-presets
            :choice-class="choice.class"
            :credits="user.credits"
            @set="setBetAmount"
          />
          <v-btn
            class="modal-btn"
            block
            :class="
              valid && bet.amount !== null ? choice.class : 'grey lighten-2'
            "
            light
            :loading="busy"
            @click="submitBet()"
          >
            SUBMIT BET
          </v-btn>
        </template>
      </v-card>
    </v-dialog>



  <div class="d-flex pa-3 draw-text-container">
    <p class="caption mb-0 white--text">
      DRAW WINS x 8. Max. DRAW bet per player: 5000/fight
    </p>
  </div>
  </div>
</template>


<script>

import helpers from "./../mixins/helpers";
import rules from "./../mixins/rules";
import BetSide from "./../components/BetSide";
import BetPresets from "./../components/BetPresets";
// import gtag from "./../mixins/gtag";

export default {
  mixins: [helpers, rules],
  components: {
    BetSide,
    BetPresets,
  },
  props: [
    "game",
    "user",
    "sideColors",
    "statusLabel",
    "submitCallback",
  ],
  data: () => ({
    defaultPlaceholder: "Bet Amount",
    placeholder: null,
    label: "",
    error: null,
    busy: false,
    choice: null,
    openModal: false,
    confirm: false,
    valid: true,
    win: null,
    bet: {
      side: null,
      amount: null,
      site_key: window.SETTINGS.SITE_KEY
    },
    lastBet: {
      side: null,
      amount: null,
      timestamp: null,
    },
  }),
  computed: {
    hint() {
      return `You have ${this.formatMoney(
        this.user.credits
      )} available credits`;
    },
    creditsRule() {
      return (
        this.bet.amount <= parseFloat(this.user.credits) ||
        `You only have ${this.formatMoney(this.user.credits)} credits`
      );
    },
    canBet() {
      return this.game && (this.game.status === 1 || this.game.status === 2);
    },
    gameStatusMessage() {
      if (this.game && this.statusLabel) {
        if (this.game.status > 2)
          return `Fight #${
            this.game.game_num
          } is already ${this.statusLabel.toLowerCase()}`;
        if (this.game.status === 0) return `Fight is not yet open.`;
      }
      return null;
    },
  },
  methods: {
    getTimeStamp() {
      return +new Date() / 1000;
    },
    setChoice(evt) {
      this.choice = evt;
      this.bet.side = evt.side;
      this.confirm = false;
      this.openModal = true;

      /*const param  = {
        
      };*/

      // gtag('event', 'registered', {'event_category' : 'reseller'});
      //window.fireRegisteredEvent(param);
                
      window.fireRegisteredEvent('attempt_bet_on_', '', evt.side);

    },
    setBetAmount(amount) {
      if (amount) this.bet.amount = amount;
      this.confirm = true;
    },
    setLastBet() {
      this.lastBet = {
        side: this.bet.side,
        amount: this.bet.amount,
        timestamp: this.getTimeStamp(),
      };
    },

    //attempt_bet
    submitBet() {
      this.$refs.betForm.validate()
        ? (this.confirm = true)
        : this.$refs.betValue.focus();
    },
    submit() {
      this.error = null;
      let timestamp = this.getTimeStamp();

      if (this.bet.amount === this.lastBet.amount && this.lastBet.timestamp) {
        let secs = timestamp - this.lastBet.timestamp;
        if (secs <= window.SETTINGS.MIN_SECONDS_SAME_BET) {
          this.$store.dispatch("snackbar", {
            message:
              "Please wait for a few seconds before placing the same bet amount.",
            color: "warning",
          });
          this.openModal = false;
          return false;
        }
      }

      this.busy = true;
      const data = {
        url: `${window.SETTINGS.API_URL}/${window.SETTINGS.API_SLUG}/bets`,
        method: "POST",
        data: this.bet,
      };

      //successful_bet
      window.axios
        .request(data)
        .then(() => {
          this.submitCallback();
          this.openModal = false;
          this.$store.dispatch("snackbar", {
            message: "Bet submitted successfully!",
          });

          
          window.fireRegisteredEvent('successful_bet_on_', data.data.amount, data.data.side);
          
           console.log(data.data);
          localStorage.setItem("last-game", this.game.game_num);
          this.setLastBet();
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data && error.response.data.errors) {
            this.error =
              error.response.data.errors.amount[0] ||
              error.response.data.errors.game[0];
          }
        })
        .finally(() => {
          this.busy = false;
          this.confirm = false;
        });
    },

   
  },
  watch: {
    openModal() {
      this.$nextTick(() => {
        if (this.openModal) {
          if (this.$refs.betForm) this.$refs.betForm.reset();
          this.confirm = false;
          this.bet.amount = null;
          this.error = null;
          this.placeholder = this.defaultPlaceholder;
          this.label = "";
        }
      });
    },
  },
  created() {
    this.placeholder = this.defaultPlaceholder;
  },
};
</script>

<style lang="sass">
@import './../sass/colors.scss'

.bet-modal
  color: #333

.sides
    display: flex
    position: relative
    .side-win
        display: flex
        align-items: center
        justify-content: center
        position: absolute
        width: 100%
        top: 0
        bottom: 0
        background-color: rgba(0,0,0,.9)
        text-transform: uppercase
        .win-content
          display: block
    .spacer
        clear: both
        display: block
        min-width: 105px
    .side-section
        text-align: center
        flex-grow: 1
        overflow: hidden

        .btn-content
            text-align: right
            padding:12px
        .side-title
            background: rgba(0,0,0,.1)
            font-size: .9em
            margin-bottom: 1em
            text-transform: uppercase
            small
              font-size: .7em
        .side-amount
            font-size: 1.15em
            font-weight: 600
            display: block
            .decimal
                font-size: .7em
        .odds
            font-weight: 400
            font-size: .8em
            margin-top: .2em
        .bet-button
            background: rgba(255,255,255,.8) !important
            letter-spacing: 0em !important
            min-width: 110px !important
            padding-right: 1px !important
            padding-left: 1px !important
            font-size: .68em !important

@media screen and (max-width: 374px)
    .sides
        display: block
        .mt-3
            margin-top: 0 !important
        .pb-3
            padding-bottom: 0 !important
        .py-2
            padding: 0 !important
        .side-section
            display: flex
            align-items: center
            justify-content: space-between
            
            
            .side-title
                float: left
                margin-top: 1.1em
                margin-left: 1em
                background: none
                min-width: 60px
                width: 100%
                text-align: left
                display: block
                line-height: 1em
                span
                    display: none
            .side-amount
                font-size: .9em
                 margin-top: 1.2em
                width: 100%
                text-align: right
                display: block
            .odds
                font-weight: 300
                font-size: .8em
                margin-left: .9em
                display: inline
            .bet-button
                float: right
                font-size: .52em !important
                min-width: 88px !important

.red-section
    background: $side-red-color !important
    color: #ffffff !important

.blue-section
    background: $side-blue-color !important
    color: #ffffff !important

.jackpot-section
    background: $side-jackpot-color !important
    color: #333333 !important


.meron-section
    background: $side-red-color !important
    color: #ffffff !important

.wala-section
    background: $side-blue-color !important
    color: #ffffff !important

.draw-section
    background: $side-jackpot-color !important
    color: #ffffff !important


.cancelled-section
    background-color: orange !important
    color: #fff !important

.empty-section
    background-color: #666666 !important
    color: #fff !important

.add-credits-section
    background: #FBC02D !important
    color: rgba(0,0,0,.7) !important

.bet-modal-header
    text-transform: uppercase

.header-close
    color: rgba(0,0,0,.4) !important

.modal-btn
    border-top-right-radius: 0 !important
    border-top-left-radius: 0 !important

.no-radius-bottom-right
    border-bottom-right-radius: 0 !important

.no-radius-bottom-left
    border-bottom-left-radius: 0 !important

.draw-text-container 
  background: #303967


</style>
