<template>
  <v-overlay :value="overlay.show" opacity=".9" class="text-center" z-index="1000">
    <p v-if="overlay.icon && overlay.iconColor">
      <v-progress-circular
        v-if="overlay.icon === 'loading'"
        size="64"
        width="8"
        indeterminate
        :color="overlay.iconColor"
      ></v-progress-circular>
      <v-icon v-else size="64" :color="overlay.iconColor">{{ overlay.icon }}</v-icon>
    </p>
    <p v-html="overlay.message"></p>
    <v-btn small color="primary" v-if="overlay.button" @click.stop="retry()">Retry</v-btn>
  </v-overlay>
</template>
 
<script>
export default {
  computed: {
    overlay: {
      get() {
        return this.$store.state.overlay;
      },
      set(val) {
        val || this.$store.dispatch("overlayHide");
      }
    }
  },
  methods: {
    retry() {
      window.location.reload();
    }
  }
};
</script>