<template>
  <div class="">
    <page-header title="Edit Account" back-button="account" v-if="mobile" />
    <v-container class="pa-3 ma-0" fluid>
      <update-name />
      <update-password />
      <clear-history />
    </v-container>
  </div>
</template>

<script>
import UpdateName from "@/sections/UpdateName";
import UpdatePassword from "@/sections/UpdatePassword";
import ClearHistory from "@/sections/ClearHistory";
import PageHeader from "@/sections/PageHeader";

export default {
  components: {
    UpdateName,
    UpdatePassword,
    ClearHistory,
    PageHeader,
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style lang="sass" scoped>
.sheet-form
  background: rgb(37, 37, 37)
</style>