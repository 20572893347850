<template>
  <v-container class="fill-height login-container " fluid>
    <v-row no-gutters>
      <v-col cols="12" class="mx-auto ">
        <div class="d-flex flex-column flex-sm-row align-center justify-center">
          <div class="">
            <img src="./../assets/ufc-logo.png" class="login-logo" />
          </div>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="main-form"
            @submit="submit()"
          >
            <v-alert
              :color="'red'"
              class="my-4 mb-6"
              v-if="error && error.mobile"
              ><small>{{ error.mobile[0] }}</small></v-alert
            >
            <p class="body-2 my-2 white--text">Mobile Number</p>
            <v-text-field
              v-model="credentials.mobile"
              background-color="#c0c2ce33"
              solo
              single-line
              dense
              type="text"
              :rules="[rules.required]"
              :error="!!error"
              required
              autocomplete="new-password"
              hide-details="auto"
            >
            </v-text-field>
            <p class="body-2 my-2 white--text">Password</p>
            <v-text-field
              v-model="credentials.password"
              background-color="#c0c2ce33"
              solo
              single-line
              dense
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              :rules="[rules.required]"
              :error="!!error"
              @click:append="show = !show"
              @keypress.enter.stop="submit()"
              required
              autocomplete="new-password"
              hide-details="auto"
            ></v-text-field>
            <div class="text-center mt-4">
              <v-btn
                color="warning"
                class="mb-4"
                block
                @click="submit()"
                :loading="busy"
              >
                Login
              </v-btn>

              <v-btn
                color="white"
                outlined
                block
                class="mb-2"
                @click.stop="$router.push({ name: 'register' })"
              >
                Register
              </v-btn>

              <v-btn
                color="grey"
                small
                text
                @click.stop="$router.push({ name: 'forgot-password' })"
              >
                Forgot Password
              </v-btn>
              <v-btn
                color = "grey"
                class="mt-sm-0 mt-10"
                small
                text
                @click="openHelp()"
              >
                <v-icon class="mr-1">mdi-message-text-outline</v-icon>
                Contact Customer Support
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import auth from "./../mixins/auth";
import rules from "./../mixins/rules";
import { v4 as uuidv4 } from 'uuid';

export default {
  mixins: [mixin, auth, rules],
  data: () => ({
    valid: true,
    show: false,
    credentials: {
      mobile: localStorage.getItem("mobile"),
      password: null,
      guid: null,
    },
  }),
  methods: {
    submit() {
      if (localStorage.getItem("guid") == null) {
        let payloadGuid = btoa(uuidv4().replace("-", "")).substring(0, 10) + "." + this.makeid(4);
        let deviceGuid = payloadGuid + "." + btoa(payloadGuid).substring(0, 4);
        if (this.urlParams)
          this.credentials.guid = this.urlParams.get("guid") || null;
        if (!this.credentials.guid) this.credentials.guid = deviceGuid;
        localStorage.setItem("guid", this.credentials.guid);
      } else {
        this.credentials.guid = localStorage.getItem("guid");
      }
      if (this.$refs.form.validate()) this.login(this.credentials);
    },
        
    openHelp() {
      window.open(window.SETTINGS.LC_URL, "_system");
    },

    makeid(length) {
        var result           = '';
        var characters       = 'CDEFHIJKLMPQRTUVWXYZabcdefghjklmopqrsuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
      return result;
    },
  },
  mounted() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.axios.defaults.headers.common["Authorization"] = null;
  },
};
</script>
