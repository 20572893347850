<template>
  <v-row v-if="game" :class="statusCss" class=" ma-0 chan-cont">
    <v-col cols="4" class="text-center" :class="statusWrapperCss">
      <h5 class="body-2 px-2">
        <span class="text-uppercase font-weight-bold">{{ statusLabel }}</span>
      </h5>
    </v-col>
    <v-col cols="4 " class="button-slanted text-center">
      <span class="button-slanted-content">
        <div class="mt-2" v-if="loading">
          <v-progress-circular
            size="28"
            indeterminate
            color="orange"
          ></v-progress-circular>
        </div>
        <template v-else>
          <p class="mb-0 logo-text-top">ULTIMATE</p>
          <p class="mb-0 logo-text-bot">FIGHTING COCK</p>
        </template>
      </span>
    </v-col>
    <v-col cols="4">
      <h5 class="body-2 text-center font-weight-bold">
        <!-- <span>{{ game.name }}</span> &nbsp; -->
        <span v-if="showFightNum">FIGHT #{{ game.game_num }}</span>
      </h5>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["game", "statusLabel", "loading"],
  computed: {
    statusCss() {
      return {
        "bg-success": this.game.status == 1,
        "bg-info": this.game.status == 4,
        "bg-danger":
          this.game.status == 4 ||
          this.game.status == 6 ||
          this.game.status == 3 ||
          this.game.status == 5,
        "bg-warning": this.game.status == 2 || this.game.status == 0,
      };
    },
    statusWrapperCss() {
      return {
        animated: this.game.status == 2,
        flash: this.game.status == 2,
        infinite: this.game.status == 2,
      };
    },
    showFightNum() {
      return this.game && this.game.game_num;
    },
  },
};
</script>

<style lang="sass" scoped>
.game-header
  font-weight: 300

.game-status
  font-size: .9em
  text-align: right
  margin-top: .11em
  .circle
    margin-top: .1em
    margin-bottom: -.1em

.logo-text-top
    font-style: italic
    font-size: 20px
    font-weight: 600
.logo-text-bot
    font-size: 13px
    font-style: italic
    margin-left: -4px
    line-height: 0


.button-slanted
  -ms-transform: skewX(-15deg)
  -webkit-transform: skewX(-15deg)
  transform: skewX(-15deg)
  background-color: #192036
  padding:0 10px


.button-slanted-content
  -ms-transform: skewX(15deg)
  -webkit-transform: skewX(15deg)
  transform: skewX(15deg)
  display: inline-block
</style>
