export default {
    methods: {
        login(data) {
            this.postData(`/auth/login${this.queryString}`, data, response => {
                this.storeCredentials(response.data)
                this.setToken()
                this.$nextTick(() => {
                    if (response.data.user.mobile_verified) {
                        localStorage.setItem('verified', true)
                        this.$router.push({ name: `play` })
                    } else {
                        localStorage.setItem('verified', '-')
                        this.$router.push({ name: `play` })
                    }
                })
            })
        },
        register(data) {
            this.postData(`/auth/register${this.queryString}`, data, response => {
                this.storeCredentials(response.data)
                localStorage.setItem('verified', '-')
                localStorage.setItem('registered', true)
                this.$router.push({ name: 'play' })
                this.reset()
            })
        },
        verify(data, isModal) {
            this.postData(`/auth/verify`, data, () => {
                localStorage.setItem('verified', true)
                if(isModal){
                    let user_data = this.$store.state.user
                    user_data.mobile_verified = true
                    this.$store.dispatch('setUser', user_data)
                    return true
                }else{
                    this.$router.replace({ name: 'withdraw' })
                }
                this.reset()
            })
        },
        reSendPin(data) {
            this.postData(`/auth/verify/resend`, data, () => {
                this.$store.dispatch('snackbar', {
                    message: 'One Time Pin has been sent.',
                    color: 'primary'
                })
            })
        },
        storeCredentials(data) {
            if(data.logged_time){
               var prev_log_in = localStorage.getItem('prev_login')

                if(prev_log_in == data.logged_time){
                    localStorage.setItem('show_modal',false)
                }else{
                    localStorage.setItem('prev_login',data.logged_time)
                    localStorage.setItem('show_modal',true)
                }
            }
            localStorage.setItem('token', data.token)
            localStorage.setItem('attachment', data.attachment)
            localStorage.setItem('mobile', data.user.mobile)
            localStorage.setItem('user', JSON.stringify(data.user))
            // localStorage.setItem('switcher_on_boot', true)
            if (data.user.representative) localStorage.setItem('rep', JSON.stringify(data.user.representative))
        },
    }
}