<template>
  <div class="">
    <page-header title="Cash Ins" back-button="account" v-if="mobile" />
    <v-container class="pa-0 ma-0 cash-in-cont" fluid>
      <div v-if="hasList">
        <div class="mb-3">
          <div
            class="d-flex pa-3 align-center justify-space-between cash-in-container"
            v-for="(deposit, index) in lists"
            :key="index"
          >
            <span class="cn-amount">
              <div class="d-flex align-center">
                <p
                  class="body-1 mb-0 yellow--text text--darken-3 font-weight-bold"
                >
                  {{ formatMoney(parseFloat(deposit.amount)) }}
                </p>
                <v-chip
                  :color="outletColor(deposit.outlet)"
                  x-small
                  class="ml-2"
                >
                  {{
                    deposit.outlet_obj
                      ? deposit.outlet_obj.name
                      : deposit.outlet
                  }}
                </v-chip>
              </div>
              <p class="mb-0 " v-if="deposit.outlet !== 'mltpy'">
                Frm: {{ deposit.sender }} To: {{ deposit.receiver }}
              </p>
              <p
                class="mb-0 red--text text--lighten-2 caption"
                v-if="deposit.status === 'invalid'"
              >
                {{ deposit.admin_notes }}
              </p>

              <p class="caption mb-0 grey--text text--lighten-4">
                {{ deposit.date_label }} {{ deposit.time_label }}
              </p>
            </span>

            <span class="text-right cn-date">
              <p class="caption mb-0">
                {{ deposit.reference_number }}
              </p>
              <v-btn
                x-small
                depressed
                rounded
                block
                class="status-chip"
                :class="deposit.status"
              >
                {{ deposit.status }}
              </v-btn>
            </span>
          </div>
        </div>
      </div>
      <div v-else-if="lists < 1" class="text-center text-lose ">
        <div class="" v-if="loading">
          <v-skeleton-loader type="list-item-three-line"> </v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"> </v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"> </v-skeleton-loader>
        </div>
        <div v-else>
          <p class="mb-0 mt-3 body-1">No cash-ins were made yet</p>
        </div>
      </div>
      <div v-if="hasMore" class="my-3 text-center">
        <v-btn text small :loading="loading" @click.stop.prevent="loadMore()">
          Show More
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import mixins from "./../../mixins";
import list from "./../../mixins/list";
import helpers from "./../../mixins/helpers";

import PageHeader from "@/sections/PageHeader";

export default {
  mixins: [mixins, list, helpers],
  data: () => ({
    dataUrl: `/wallet/deposits`,
  }),

  components: {
    PageHeader,
  },
  methods: {
    outletColor(outlet) {
      if (outlet === "ml") {
        return "red lighten-1";
      }
      if (outlet === "gcash") {
        return "light-blue darken-3";
      }
      if (outlet === "cebuana") {
        return "cyan darken-3";
      }
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style lang="sass">

.cash-in-container

    border-bottom: 2px solid #282E40
    margin-bottom: 2px

.status-chip.valid
  background-color: #20883b  !important

.status-chip.invalid
  background-color: #a75c5c  !important

.status-chip.pending
  background-color: #2e3448 !important
  border-bottom: 1px solid #2e3448 !important



span.cn-amount p
    font-size: 14px

@media (max-width: 320px)
    span.cn-amount p
        font-size: 13px !important
</style>
