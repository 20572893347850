<template>
  <div class="d-flex pa-3 credits-container">
    <p class="body-2 mb-0 white--text ">
<!--       <v-icon color="#ed9c4a" size="20">
        mdi-cards-outline
      </v-icon> -->
      CURRENT POINTS : <strong class="yellow--text">{{ formatMoney(user.credits) }}</strong>
    </p>
    <v-spacer></v-spacer>

    <div class="" v-if="user.credits < 100">
      <v-btn x-small tile color="#009900" @click="goTo('/add-credits')">
        Add Points
      </v-btn>
    </div>

    <v-btn-toggle v-model="creditOption" tile flat v-else>
      <v-btn x-small color="#373f61 " @click="goTo('/withdraw')">
        <v-icon size="18">mdi-minus</v-icon>
      </v-btn>

      <v-btn x-small color="#009900 " @click="goTo('/add-credits')">
        <v-icon size="18">mdi-plus</v-icon>
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import helpers from "@/mixins/helpers";
export default {
  data: () => ({
    creditOption: null,
  }),
  mixins: [helpers],

  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    goTo(path) {
      if(!this.user.mobile_verified && path === "/withdraw"){
        this.$router.push({ path: "/verify" }).catch(() => {
          this.drawer = false;
        });
      }else{
        this.$router.push({ path: path }).catch(() => {
          this.drawer = false;
        });
      }
    },
  },
};
</script>

<style>
.credits-container {
  background: #1f2233;
}
</style>
