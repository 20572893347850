<template>
  <v-card color="#2e3448" class="py-4" flat tile>
    <h3 class="mb-4">BETS</h3>
    <v-btn tile block depressed color="primary" :loading="busy" @click="submit()">
      CLEAR BETS HISTORY</v-btn
    >
  </v-card>
</template>

<script>
import data from "./../mixins/data";

export default {
  mixins: [data],
  data: () => ({
    busy: false,
  }),
  methods: {
    submit() {
      this.postData(`/${window.SETTINGS.API_SLUG}/bets/clear`, {}, () => {
        this.$store.dispatch("prompt", { message: "Choices history cleared!" });
      });
    },
  },
};
</script>
