<template>
  <v-card color="#2e3448" tile flat class="py-4">
    <h3>UPDATE PASSWORD</h3>
    <v-form
      ref="formPassword"
      v-model="credentials.valid"
      lazy-validation
      class="mb-4 wallet-form"
      @submit="submit()"
    >
      <p class="my-2 body-2">Current Password</p>
      <v-text-field
        solo
        dense
        hide-details="auto"
        background-color="#58658d"
        v-model="credentials.current_password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'"
        @click:append="show1 = !show1"
        :loading="busy"
        :rules="[rules.required]"
        :error-messages="
          error && error.current_password ? error.current_password[0] : ''
        "
        required
      ></v-text-field>
      <p class="my-2 body-2">New Password</p>
      <v-text-field
        solo
        dense
        hide-details="auto"
        background-color="#58658d"
        v-model="credentials.password"
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show2 ? 'text' : 'password'"
        @click:append="show2 = !show2"
        :loading="busy"
        :rules="[rules.required, rules.password]"
        :error-messages="error && error.password ? error.password[0] : ''"
        required
      ></v-text-field>
      <p class="my-2 body-2">Repeat New Password</p>
      <v-text-field
        solo
        dense
        hide-details="auto"
        background-color="#58658d"
        v-model="credentials.password_confirmation"
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show2 ? 'text' : 'password'"
        @click:append="show2 = !show2"
        :loading="busy"
        :rules="[rules.required, rules.password, passwordConfirmationRule]"
        :error-messages="
          error && error.password_confirmation
            ? error.password_confirmation[0]
            : ''
        "
        @keypress.enter="submit()"
        required
      ></v-text-field>
    </v-form>
    <v-btn
      block
      tile
      depressed
      color="primary"
      :loading="busy"
      @click="submit('password')"
      >UPDATE
    </v-btn>
  </v-card>
</template>

<script>
import data from "./../mixins/data";
import rules from "./../mixins/rules";

export default {
  mixins: [data, rules],
  data: () => ({
    busy: false,
    error: null,
    credentials: {
      current_password: null,
      password: null,
      password_confirmation: null,
      valid: true,
    },
    show1: false,
    show2: false,
    show3: false,
  }),
  computed: {
    passwordConfirmationRule() {
      return (
        this.credentials.password === this.credentials.password_confirmation ||
        "Password must match"
      );
    },
  },
  methods: {
    submit() {
      if (this.$refs.formPassword.validate()) {
        this.postData(
          `/${window.SETTINGS.API_SLUG}/account/password`,
          this.credentials,
          () => {
            this.$refs.formPassword.reset();
            this.$store.dispatch("prompt", { message: "Password updated!" });
          }
        );
      }
    },
  },
};
</script>
