<template>
  <v-sheet
    v-if="client.settings.derby_title"
    color="#181f35"
    class="fight-description p-relative"
  >
    <div class="pa-3">
      <strong>{{ client.settings.derby_title }}</strong>
    </div>
    <div
      class="derby-program text-truncate pa-3 pt-0"
      v-if="client.status === 'closed'"
    >
      This arena is currently not available
    </div>
    <div
      class="derby-program  pa-3 pt-0"
      v-else-if="client.settings.derby_url"
    >
      Derby Program:
      <a :href="client.settings.derby_url" target="_blank">
        {{ client.settings.derby_desc }}
      </a>
    </div>
    <!-- <switcher :clients="clients" :client="client" class="switcher-btn" /> -->
  </v-sheet>
</template>

<script>
// import Switcher from "@/modals/Switcher";

export default {
  props: ["client"],
  components: {
    // Switcher,
  },
  computed: {
    clients() {
      return this.$store.state.clients;
    },
  },
};
</script>

<style lang="sass">
.fight-description
    padding-right: 95px
    strong
        font-weight: 500
    a
        color: #82c3f7 !important

.derby-program
    font-size: .85em !important
    margin-top: -.8em

.switcher-btn
    position: absolute
    right: 0
    top: .6em

@media (max-width: 374px)
    .fight-description
        font-size: .8em !important
</style>