<template>
  <div class="">
    <page-header title="RULES AND GUIDELINES" back-button="account" v-if="mobile" />
    <v-container class="pa-3 ma-0 mt-4" fluid>
      <p>RULES ON BETTING</p>
      <ol style="font-size: .9em">
        <li class="mb-3">
          FIGHT WITH 107 AND BELLOW PAYOUT, SHALL BE CANCELLED.
        </li>
      </ol>
      <p class="mt-10">RULES ON LOAD</p>
      <ol style="font-size: .9em">
        <li class="mb-3">
          UFC IS NOT RESPONSIBLE FOR YOUR CASH-IN/CASH-OUT USING DIFFERENT ACCOUNT NUMBERS.
        </li>
        <li class="mb-3">
          WE CAN ONLY CATER TO THOSE CASH-IN/CASH-OUT UNDER OUR ACCOUNT NUMBERS.
        </li>
        <li class="mb-3">
          WE ARE NOT RESPONSIBLE TO ANY AGREEMENT YOU MADE WITH YOUR COORDINATION..
        </li>

      </ol>

      <v-row>
        <v-col cols="12">
          <v-btn tile block color="#ff5100" @click.stop="openHelp()">
            <v-icon class="mr-1">mdi-message-text-outline</v-icon>
            Contact Customer Support
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import mixin from "./../mixins";
import PageHeader from "@/sections/PageHeader";

export default {
  mixins: [mixin],
  components: {
    PageHeader,
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    openHelp() {
      window.open(window.SETTINGS.LC_URL, "_system");
    }
  }
};
</script>