<template>
    <v-overlay :value="win.show" opacity=".5" class="text-center" close-delay="5000">
        <div class="win-content text-center">
        <v-card color="#fff" width="280px" max-width="350px" class="pa-4">
          <div class="win-result mb-3" v-if="win.message">
            <h2 class="mb-2 green--text text-uppercase  font-weight-black">Congratulations!</h2>
            <p class="mb-0 black--text">YOU WIN</p>
            <h2 class="warning--text font-weight-black heart">{{ formatMoney(win.message.win_amount) }}</h2>
            <div class="d-flex justify-center align-center my-4">
                <p class="body-1 mb-0 black--text mr-2">Fight #{{ win.message.game_num }}:</p> 
                <span class="text-uppercase white--text px-2" :class="'trend-' + gameSide[win.message.winner - 1]">
                <strong>{{ gameSide[win.message.winner - 1] }}</strong>
              </span> 
            </div>
          </div>
          <v-btn block color="warning" @click.stop="win.show = false">OK</v-btn>
          <div class="mt-4" v-if="win.ad" v-html="win.ad"></div>
        </v-card>
        </div>
    </v-overlay>
</template>

<script>
import helpers from "./../mixins/helpers.js";

export default {
  mixins: [helpers],
  computed: {
    gameSide () {
      return this.$store.state.sideColors || []
    },
    win: {
      get() {
        return this.$store.state.win;
      },
      set(val) {
        val || this.$store.dispatch("winHide");
      }
    }
  }
};
</script>