<template>
  <div>
    <v-dialog light v-model="commissionModal.show" max-width="300" v-show="user.type !== 'player' && user.inv_link">
      <v-card class="wallet-modal">

        <div class="">
          <v-container class="pa-3 ma-0 mt-4" fluid>
            <p class="text-center font-weight-bold text-capitalize">Instructions on viewing earnings from invite / commissions</p>
            <ol style="font-size: .9em">
              <li class="mb-3">
                CLICK ON THE BUTTON BELOW TO GO TO OUR RESELLER/COOR WEBSITE
              </li>

              <li class="mb-3">
                YOU CAN LOG IN TO OUR RESELLER WEBSITE USING THE SAME CREDENTIALS YOU USE ON UFC
              </li>

              <li class="mb-3">
                YOU CAN VIEW OR CONVERT YOUR EARNINGS ON OUR RESELLER/COOR WEBSITE
              </li>

            </ol>

            <v-row>
              <v-col cols="12">
                <!-- <v-btn tile block color="#fb8c00 !important" @click.stop="openHelp()"> -->
                  <!-- <v-icon class="mr-1">mdi-facebook </v-icon> -->
                  <!-- <span color="red--text">VIEW COMMISSIONS HERE</span>
                </v-btn> -->
              </v-col>
            </v-row>
          </v-container>
        </div>

        <v-btn
          class="modal-btn elevation-0"
          block
          color="grey lighten-2"
          light
          @click="$store.dispatch('hideCommissionModal')"
        >
          Close
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    qr: null,
  }),
  computed: {
    canShare() {
      return navigator.canShare;
    },
    user() {
      return this.$store.state.user;
    },
    commissionModal: {
      get() {
        return this.$store.state.commissionModal;
      },
      set(val) {
        val || this.$store.dispatch("hideCommissionModal");
      },
    },
  },
  methods: {
    openHelp() {
      window.open(window.SETTINGS.RESELLER, "_system");
    }
  },
  watch: {
    "commissionModal.show"() {
      if (this.user && this.commissionModal.show) this.showQR();
    },
  },
};
</script>

<style lang="sass">
.invitation-link
    border: 1px solid #2196f3
    background: 1px solid #eee !important
    color: #333 !important
    font-size: .8em
    border-radius: 4px
    width: 100%
</style>