<template>
  <div class="">
    <page-header title="Advance Bets" back-button="play" v-if="mobile">
      <!-- <a
        href="#"
        v-if="hasBets > 1"
        class="red--text no-underline d-block mt-1"
        @click.stop.prevent="showRemove = !showRemove"
        >Remove</a
      > -->
    </page-header>
    <v-container class="pa-0 ma-0" fluid>
      <!-- <v-sheet v-if="!canAddMore" color="warning" class="ma-3 pa-3 mb-0">
        You can only have {{ maxAdvanceBets }} advance bets.
      </v-sheet> -->
      <div class="page-content py-2 pt-5">
        <!-- <div class="play-page d-flex px-3 mt-1 mb-1" v-if="!mobile">
          <v-spacer></v-spacer>
          <a
            href="#"
            v-if="hasBets > 1"
            class="red--text no-underline"
            @click.stop.prevent="showRemove = !showRemove"
            >Remove</a
          >
        </div> -->
        <div class="d-flex mx-3">
          <span class="mr-2">Fight #</span>
          <v-spacer />
          Amount
        </div>
        <v-slide-y-transition class="py-0" group tag="div">
          <div
            v-for="(bet, index) in bets"
            :key="index"
            class="d-flex adv-opt-container px-3 py-4 pr-2"
          >
            <div class="flex-grow-1 adv-num pr-3">
              <v-text-field
                tile
                dense
                solo
                v-model="bet.sequence"
                type="number"
                step="1"
                @blur="checkFightNum"
                hide-details
                required
              ></v-text-field>
            </div>
            <div class="flex-grow-1 adv-opt">
              <div class="d-flex mr-4" v-if="bet.side">
                <div
                  v-for="(side, s) in sideColors"
                  :key="s"
                  @click="bet.side = s + 1"
                  style="background-color: #232831"
                  class="selected adv-side mr-2 elevation-2 ellipsis"
                  :class="
                    side === sideColors[bet.side - 1]
                      ? sideColors[bet.side - 1] + '-section'
                      : ''
                  "
                >
                  <span v-if="bet.side === s + 1">
                    <small class="text-capitalize adv-opt-label">
                      {{ sideColors[s] }}
                    </small>
                    <v-icon class="adv-check" size="20">
                      mdi-checkbox-marked-circle
                    </v-icon>
                  </span>

                  <small
                    v-else
                    :class="
                      sideColors[s] + '-text text-capitalize adv-opt-label'
                    "
                  >
                    {{ sideColors[s] }}
                  </small>
                </div>
              </div>
              <div class="d-flex mr-4" v-else>
                <div
                  v-for="(side, ss) in sideColors"
                  :key="ss"
                  @click="bet.side = ss + 1"
                  class="adv-side mr-2 elevation-2 ellipsis"
                  :class="sideColors[ss] + '-empty'"
                >
                  <span>
                    <small class="text-capitalize">{{ sideColors[ss] }}</small>
                  </span>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 adv-opt">
              <div class="text-right">
                <v-btn
                  tile
                  color="#596188"
                  class="adv-amount elevation-1"
                  @click.stop.prevent="editBet(index)"
                  >{{
                    bet.amount > 0 ? formatMoney(bet.amount) : "AMOUNT"
                  }}</v-btn
                >
              </div>
            </div>
            <div v-show="showRemove" class="">
              <v-icon
                  class="adv-remove red--text ml-1 mt-2"
                  @click.stop.prevent="remove(index)"
                  >mdi-close</v-icon
                >
            </div>
          </div>
        </v-slide-y-transition>
        <v-layout class="mx-3 align-center mt-4 mb-4">
          <v-flex >
            <v-btn
              text
              small
              class="adv-btn"
              @click="addRow()"
              :disabled="!canAddMore"
              color="success"
              ><v-icon size="12" class="mr-1">mdi-plus</v-icon> ADD MORE</v-btn
            >
            <v-btn
              text
              small
              class="adv-btn"
              :disabled="!canRemove"
              @click="removeRow()"
              color="red"
              ><v-icon size="12" class="mr-1">mdi-minus</v-icon> REMOVE</v-btn
            >
          </v-flex>
          <v-flex class="text-right">
            <span
              class="body-1 orange--text font-weight-bold"
              :class="{ 'red--text': isOver }"
            >
              <small class="grey--text text--lighten-1">Total: </small>
              {{ formatMoney(totalAmount) }}</span
            >
          </v-flex>
        </v-layout>
        <v-layout class="mx-3 my-2 pt-1">
          <v-flex xs-6 v-if="hasBets" class="text-right">
            <v-btn
              tile
              block
              class=""
              :disabled="this.totalAmount < 5"
              @click="confirm()"
              color="primary"
              >SUBMIT</v-btn
            >
          </v-flex>
        </v-layout>
      </div>
      <advance-bets 
        :side-colors="sideColors" 
        @last="lastSequenceEvt" 
        @total="lastBetsEvt"
      />
      <v-dialog light class="bet-modal" v-model="openModal" max-width="300">
        <v-card>
          <div
            class="bet-modal-header d-flex py-2 px-3 elevation-1"
            :class="choiceClass"
          >
            <strong>AMOUNT</strong>
            <v-spacer></v-spacer>
            <v-icon class="header-close" @click="openModal = false"
              >mdi-close</v-icon
            >
          </div>
          <v-card-text
            class="pa-2"
          >
            <v-form
              ref="betForm"
              v-model="valid"
              lazy-validation
              @submit="setBetModal()"
            >
               <v-text-field
                style="margin-top: 1px"
                dense
                outlined
                hide-details="auto"
                ref="betValue"
                v-model="bet.amount"
                :min="1"
                :label="label"
                :placeholder="placeholder"
                type="number"
                :rules="[rules.amountRule, rules.minAmountRule, creditsRule]"
                :error="!!error"
                :error-messages="error || ''"
                :hint="hint"
                :disabled="busy"
                @focus="
                  label = 'Amount';
                  placeholder = '';
                "
                @blur="
                  bet.amount === null ? (label = 'Amount') : (label = '');
                  placeholder = defaultPlaceholder;
                "
                @keyup.enter="submitBet()"
                required
                clearable
              ></v-text-field>
            </v-form>
          </v-card-text>
          <bet-presets
            :choice-class="choiceClass"
            :credits="user.credits"
            @set="setBetAmount"
          />
          <v-btn
            class="modal-btn"
            block
            :class="
              valid && bet.amount !== null ? choiceClass : 'grey lighten-2'
            "
            light
            :loading="busy"
            @click="setBetModal()"
            >{{ edit ? "OK" : "ADD" }}</v-btn
          >
        </v-card>
      </v-dialog>
      <v-dialog light v-model="confirmModal" max-width="300" :persistent="busy">
        <v-card>
          <div dark class="d-flex py-2 px-3">
            <strong>CONFIRM</strong>
            <v-spacer></v-spacer>
            <v-icon class="header-close" @click="confirmModal = false"
              >mdi-close</v-icon
            >
          </div>
          <v-divider></v-divider>
          <v-card-text class="text-center black--text py-5 px-4">
            <div v-if="busy" class="px-2 pb-3 pt-2">
              Submitting...
              <br />
              <v-progress-circular
                class="loading-circle mt-2"
                size="36"
                indeterminate
                color="orange"
              />
            </div>
            <div v-else>
              Are you sure you want to
              <br />submit
              <strong>{{ hasBets }}</strong>
              advance bet{{ hasBets > 1 ? "s" : null }}?
              <div class="mt-2">
                Total:
                <strong>{{ this.formatMoney(this.totalAmount) }}</strong>
              </div>
            </div>
          </v-card-text>
          <div class="d-flex">
            <div class="flex-grow-1">
              <v-btn
                class="modal-btn no-radius-bottom-right elevation-0"
                block
                color="grey lighten-2"
                light
                @click="confirmModal = false"
                >NO</v-btn
              >
            </div>
            <div class="flex-grow-1">
              <v-btn
                class="modal-btn no-radius-bottom-left elevation-0"
                block
                color="primary"
                :disabled="busy"
                @click="submitBets()"
                >YES</v-btn
              >
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import mixin from "./../mixins";
import data from "./../mixins/data";
import helpers from "./../mixins/helpers";
import rules from "./../mixins/rules";
import BetPresets from "./../components/BetPresets";
import AdvanceBets from "./../sections/AdvanceBets";
import PageHeader from "@/sections/PageHeader";

export default {
  mixins: [mixin, data, helpers, rules],
  data: () => ({
    defaultPlaceholder: "Or enter amount here",
    placeholder: null,
    label: "",
    minBet: 50,
    edit: false,
    showRemove: false,
    startingThrow: window.SETTINGS.GAMES_IN_ADVANCE,
    startSet: false,
    bets: [
      {
        sequence: "",
        side: null,
        amount: null,
      },
    ],
    bet: {
      sequence: "",
      side: 1,
      amount: 100,
    },
    valid: false,
    validStart: false,
    lastSequence: 0,
    lastBets: 0,
    openModal: false,
    confirmModal: false,
    gameDiff: 0,
    canAddMore: true,
  }),
  components: {
    BetPresets,
    PageHeader,
    AdvanceBets
  },
  computed: {
    hasBets() {
      return this.bets.length;
    },
    canRemove() {
      return this.bets.length > 1
    },
    totalAmount() {
      return this.bets.reduce((prev, cur) => {
        return cur.side ? prev + parseFloat(cur.amount) : prev;
      }, 0);
    },
    isOver() {
      return this.totalAmount > parseFloat(this.user.credits)
        ? this.totalAmount - parseFloat(this.user.credits)
        : false;
    },
    prevAmount() {
      return this.hasBets > 1 ? this.bets[this.hasBets - 1].amount : 100;
    },
    user() {
      return this.$store.state.user;
    },
    choiceClass() {
      return this.bet.side
        ? `${this.sideColors[this.bet.side - 1]}-section`
        : "empty-section";
    },
    hint() {
      let amount = parseFloat(this.user.credits) - this.totalAmount;
      return `You have ${this.formatMoney(amount)} available credits`;
    },
    creditsRule() {
      return (
        this.totalAmount <= parseFloat(this.user.credits) ||
        `Not enough credits`
      );
    },
    gamesInAdvance() {
      return window.SETTINGS.GAMES_IN_ADVANCE;
    },
    gamesMaxAdvance() {
      return parseInt(this.$store.state.client.settings.exp_games) || 
      window.SETTINGS.GAMES_MAX_ADVANCE;
    },
    maxAdvanceBets () {
      return window.SETTINGS.MAX_ADVANCE_BETS
    },
    game() {
      return this.$store.getters.game;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    setBetModal() {
      if (this.$refs.betForm.validate()) {
        this.openModal = false;
      }
    },
    addBet() {
      this.bets.push({
        side: this.bet.side,
        amount: parseFloat(this.bet.amount),
        sequence: this.bet.sequence,
      });
    },
    editBet(index) {
      this.bet = this.bets[index];
      this.edit = true;
      this.openModal = true;
    },
    setBetAmount(amount) {
      this.bet.amount = amount;
      this.openModal = false;
    },
    checkCanAddMore(num = 1) {
      this.$nextTick(() => {
        this.canAddMore = (this.bets.length + this.lastBets) < this.maxAdvanceBets
        if (!this.canAddMore) {
          this.$store.dispatch("snackbar", {
            message: `You can only have ${this.maxAdvanceBets} advance bets`,
            color: "warning",
          });
        }
        if (num > this.gamesMaxAdvance) this.fightNumWarning()
      })
    },
    checkFightNum(evt) {
      if (evt.target.value > this.gamesMaxAdvance) {
        this.fightNumWarning()
        evt.target.value = null
      }
    },
    fightNumWarning() {
      this.$store.dispatch("snackbar", {
        message: `Max fight number is ${this.gamesMaxAdvance}`,
        color: "warning"
      });
    },
    addRow() {
      this.$nextTick(() => {
        if (this.hasBets) {
          let bet = this.bets[this.hasBets - 1];
          if (this.isOver) {
            this.$store.dispatch("snackbar", {
              message: "Not enough credits...",
              color: "warning",
              timeout: 1200,
            });
            return;
          }
          if (bet.sequence && bet.side && bet.amount) {
            let fightNum = parseInt(this.bets[this.bets.length - 1].sequence) + 1
            if (fightNum <= this.gamesMaxAdvance) {
              this.bets.push({
                side: null,
                amount: this.prevAmount,
                sequence: fightNum,
              });
            }
            this.checkCanAddMore(fightNum)
          } else {
            let missing = [];
            if (!bet.sequence) missing.push("enter fight number");
            if (!bet.side) missing.push("choose a side");
            if (!bet.amount) missing.push("enter an amount");

            let missingLabel = missing.join(", ");

            this.$store.dispatch("snackbar", {
              message: "Please " + missingLabel + " for #" + this.hasBets,
              color: "warning",
              timeout: 1200,
            });
          }
        }
      })
    },
    removeRow() {
      this.bets.pop()
      this.checkCanAddMore()
    },
    openBetModal() {
      if (this.$refs.betForm) this.$refs.betForm.reset();
      this.bet.sequence = null;
      this.error = null;
      this.edit = false;

      this.bets.push({
        side: null,
        amount: this.bet.amount,
        sequence: null,
      });
    },
    remove(index) {
      this.$nextTick(() => {
        this.bets.splice(index, 1);
        this.checkCanAddMore()
      });
    },
    cleanBets() {
      let newBets = [];
      this.bets.forEach((bet) => {
        // bet.sequence = index + 1;
        if (bet.side && bet.amount) newBets.push(bet);
      });
      this.bets = newBets;
    },
    confirm() {
      this.cleanBets();
      if (this.isOver) {
        this.$store.dispatch("snackbar", {
          message: "Not enough credits...",
          color: "warning",
          timeout: 1200,
        });
        return;
      }
      if (this.totalAmount > 0) {
        this.confirmModal = true;
      } else {
        this.$store.dispatch("snackbar", {
          message: "You have not added a bet yet",
          color: "info",
          timeout: 1200,
        });
        return;
      }
    },
    submitBets() {
      this.postData(
        `/${window.SETTINGS.API_SLUG}/advance-bets`,
        { bets: this.bets },
        () => {
          this.confirmModal = false;
          this.$store.dispatch("snackbar", {
            message: "Advance bets submitted successfully",
            color: "success",
            timeout: 3600,
          });
          this.bets = [
            {
              sequence: "",
              side: null,
              amount: this.user.credits <= 100 ? this.user.credits : 100,
            },
          ];
          this.$store.dispatch("appEvent", "advance-bets-submitted");
        },
        () => {
          this.confirmModal = false;
        }
      );
    },
    startingFilter() {
      this.$nextTick(() => {
        if (this.game) {
          let startAt =
            parseInt(this.game.game_num) +
            this.gamesInAdvance +
            this.lastSequence;
          this.gameDiff = this.startingThrow - startAt;
          if (this.startingThrow < startAt) this.startingThrow = startAt;
        }
        if (!this.startingThrow || this.startingThrow < this.gamesInAdvance)
          this.startingThrow = this.gamesInAdvance;
        if (this.lastSequence) this.startingThrow = this.lastSequence + 1;
      });
    },
    lastSequenceEvt(evt) {
      if (evt && evt.sequence) this.lastSequence = evt.sequence;
    },
    lastBetsEvt(evt) {
      if (evt) this.lastBets = evt;
      this.checkCanAddMore();
    },
    updateGameNum() {
      if (this.game && this.game.game_num) {
        this.lastSequence
          ? (this.startingThrow = this.lastSequence + 1)
          : (this.startingThrow =
              this.game.game_num + this.gameDiff + this.gamesInAdvance);
      } else {
        this.lastSequence
          ? (this.startingThrow = this.lastSequence + 1)
          : (this.startingThrow = this.gamesInAdvance);
      }
    },
  },
  watch: {
    hasBets() {
      if (this.hasBets < 2) this.showRemove = false;
    },
    lastSequence() {
      if (this.lastSequence) this.bets[0].sequence = this.lastSequence + 1;
    },
    startingThrow() {
      this.$nextTick(() => {
        if (this.startingThrow > this.gamesMaxAdvance)
          this.startingThrow = this.gamesMaxAdvance;
      });
    },
  },
  mounted() {
    this.getGame();
    this.$nextTick(() => {
      if (this.user.credits < 100) this.bet.amount = this.user.credits;
      this.bets[0].amount = this.bet.amount;
      this.bets[0].sequence = this.game
        ? this.game.game_num + this.gamesInAdvance
        : 1;
      this.placeholder = this.defaultPlaceholder;
    });
  },
};
</script>

<style lang="sass">
.side-tab-button
  float: left
  width: 33.3333333333333%
  font-size: .8em
  font-weight: 500
  overflow: hidden
  white-space: no-wrap
  border-top-right-radius: 4px
  border-top-left-radius: 4px

.adv-opt-container
  border-bottom: 1px solid #222
  .v-input__slot
    border-radius: 0

.adv-opt-label
  display: block
  margin-top: 3px

.adv-num
  padding: 0
  max-width: 70px !important

.adv-side
  position: relative
  width: 53px
  height: 39px
  display: inline-block
  text-align: center
  line-height: 34px
  .adv-check
    position: absolute
    top: -5px
    right: -5px

.adv-amount
  padding: 20px 10px !important

.red-section-dark
  background: #B7181C !important
  color: #ffffff !important

.blue-section-dark
  background: #0D3FCC !important
  color: #ffffff !important

.jackpot-section-dark
  background: #CCBC2F !important

.meron-section-dark
  background: #B7181C !important
  color: #ffffff !important

.wala-section-dark
  background: #0D3FCC !important
  color: #ffffff !important

.draw-section-dark
  background: #CCBC2F !important
</style>
