<template>
  <v-container class="fill-height register-container" fluid>
    <v-row>
      <v-col cols="12 " class=" mx-auto ">
        <div class="d-flex  align-center justify-center">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="main-form"
            @submit="submit()"
          >
            <h3 class=" text-center">REGISTER</h3>
            <p class="body-2 my-2 white--text">Mobile Number</p>
            <v-text-field
              v-model="credentials.mobile"
              type="number"
              :loading="busy"
              :rules="[rules.required, rules.phone]"
              :error-messages="error && error.mobile ? error.mobile[0] : ''"
              required
              autocomplete="new-password"
              solo
              dense
              hide-details="auto"
              background-color="#c0c2ce33"
            >
            </v-text-field>
            <p class="body-2 my-2 white--text">Name</p>
            <v-text-field
              v-model="credentials.name"
              :loading="busy"
              :rules="[rules.required]"
              :error-messages="error && error.name ? error.name[0] : ''"
              required
              autocomplete="new-password"
              solo
              dense
              hide-details="auto"
              background-color="#c0c2ce33"
            >
            </v-text-field>
            <p class="body-2 my-2 white--text">Password</p>
            <v-text-field
              v-model="credentials.password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              :loading="busy"
              :rules="[rules.required, rules.password]"
              :error-messages="error && error.password ? error.password[0] : ''"
              required
              autocomplete="new-password"
              solo
              dense
              hide-details="auto"
              background-color="#c0c2ce33"
            ></v-text-field>

            <p class="body-2 my-2 white--text">Confirm Password</p>
            <v-text-field
              v-model="credentials.password_confirmation"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"
              :loading="busy"
              :rules="[
                rules.required,
                rules.password,
                passwordConfirmationRule,
              ]"
              :error-messages="
                error && error.password_confirmation
                  ? error.password_confirmation[0]
                  : ''
              "
              @keypress.enter="submit()"
              required
              autocomplete="new-password"
              solo
              dense
              hide-details="auto"
              background-color="#c0c2ce33"
            ></v-text-field>
            <div class="mt-2 mb-4">
              <v-checkbox
                class="mt-0 mb-1"
                v-model="credentials.terms"
                required
                hide-details
                :rules="[(v) => !!v || '']"
              >
                <template v-slot:label>
                  <a
                    href="#"
                    class="terms-link"
                    @click.stop.prevent="
                      terms = true;
                      credentials.terms = true;
                    "
                  >
                    &nbsp;Terms and Conditions
                  </a>
                </template>
              </v-checkbox>
            </div>
            <div class="text-center mt-5 pt-3">
              <v-btn
                color="warning"
                class="mb-4"
                @click="submit()"
                :loading="busy"
                block
                >Register</v-btn
              >

              <v-btn
                color="white"
                block
                outlined
                class="mb-2"
                @click.stop="$router.push({ name: 'login' })"
                >Login</v-btn
              >
              <div v-if="this.credentials.ref" color="grey--text">
                <small>{{ this.credentials.ref }}</small>
              </div>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>

    <v-dialog light v-model="terms" max-width="600">
      <v-card class="terms-modal">
        <div class="win-content px-5 py-4 text-justify">
          <terms />
        </div>
        <v-card-actions class="sticky-bottom">
          <v-btn block color="primary" light @click.stop.prevent="terms = false"
            >I AGREE</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog light v-model="show_prompt" max-width="600">
      <v-card class="terms-modal">
        <v-card-title color="warning" class="text-h6">
          Confirm Number
        </v-card-title>
        <v-divider></v-divider>
        <div class="win-content px-5 py-4 text-justify">
          Please make sure that phone number is active and available.
          Phone number will be used for verification later on.
        </div>
        <v-divider></v-divider>
        <v-card-actions class="text-right">
          <div class="reg-prompt-btn">
            <v-btn medium color="grey" class="mx-2" @click.stop.prevent="willProceed(false)">
              Cancel
            </v-btn>
            <v-btn medium color="warning" @click.stop.prevent="willProceed(true)">
              OK
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import auth from "./../mixins/auth";
import rules from "./../mixins/rules";

export default {
  mixins: [mixin, auth, rules],
  data: () => ({
    valid: true,
    show1: false,
    show2: false,
    terms: false,
    show_prompt: false,
    credentials: {
      mobile: null,
      name: null,
      password: null,
      password_confirmation: null,
      guid: null,
      ref: null,
      terms: false,
    },
  }),
  components: {
    Terms: () => import("./../modals/Terms.vue"),
  },
  computed: {
    passwordConfirmationRule() {
      return (
        this.credentials.password === this.credentials.password_confirmation ||
        "Password must match"
      );
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) this.show_prompt = true;
    },
    verifyInvitationCode() {
      this.getData(
        `/auth/verify-invitation-code/${this.credentials.ref}`,
          response => {
            if (response.exists === undefined || !response.exists) {
              this.redirectTo404()
            }
          },
          () => {
            this.redirectTo404()
          }
      )
    },
    redirectTo404() {
      this.$router.replace({ name: 'not-found' })
    },
    willProceed(will_proceed) {
      this.show_prompt = false;
      if(will_proceed)this.register(this.credentials);
    }
  },
  mounted() {
    if (this.$route.query) {
      this.credentials.guid = this.$route.query?.guid || null;

      if(this.$route.query.ref) {
        this.credentials.ref = this.$route.query.ref || null;
        this.verifyInvitationCode();
      }
    }
  },
};
</script>
