<template>
  <v-col cols="12" class="col-md-6 py-1 px-3">
    <v-card class="d-flex align-center pr-2" tile flat color="#22273a">
      <div v-if="num" class="bet-number" :class="betSide">
        <p class="body-2 mb-0">#{{ num }}</p>
      </div>

      <div class="bet-side" :class="betSide">
        <p class="body-2 mb-0">
          <span class="white--text">{{ amount }}</span>

          <v-icon
            v-if="isAdvanced"
            size="14"
            color="yellow"
            class="advance-flag ml-1"
            >mdi-clock-time-four-outline</v-icon
          >
        </p>
      </div>
      <v-spacer></v-spacer>
      <div class="bet-result align-center">
        <slot></slot>
      </div>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: ["num", "side", "amount", "winner", "isAdvanced"],

  computed: {
    betSide() {
      return this.side.toLowerCase();
    },
    listClass() {
      return this.side
        ? `d-inline-block circle trend-item trend-${this.side.toLowerCase()} choices-circles mr-2`
        : null;
    },
    winnerClass() {
      return this.winner;
    },
  },

  methods: {
    winnerColor(winner) {
      if (winner === null) {
        return "ongoing";
      }
      if (winner === "-1") {
        return "cancelled";
      }
      if (winner === "1") {
        return "meron";
      }
      if (winner === "2") {
        return "wala";
      }
      if (winner === "3") {
        return "draw";
      }
    },
  },
};
</script>

<style lang="sass">
@import './../sass/colors.scss'


.bet-number
  padding: 10px 5px
  background: #212121
  text-align: center
  min-width: 45px
  margin-right: 10px
  color: #f2f2f2
  border-radius:0 !important
  &.ongoing
    background: #519152

  &.draw
    background: #009900
    opacity: 0.8

  &.meron
    background: #dc5b5a
    opacity: 0.8
  &.wala
    background: #2a72c3
    opacity: 0.8
.bet-side

  &.meron
    color:#dc5b5a
  &.wala
    color:#2a72c3
  &.draw
    color:#009900


.choices-circles
  margin-bottom: -0.2em
  margin-top: -0.2em
.advance-flag
  margin-top: -2px !important

@media screen and (max-width: 374px)
  .bet
    font-size: .8em !important
</style>
