<template>
  <div class="">
    <page-header title="Withdraw" back-button="account" v-if="mobile" />
    <v-container class="pa-3 ma-0" fluid>
      <h4 class="text-right">Points: <strong class="yellow--text">{{formatMoney(user.credits)}}</strong></h4> 
      <div class="mb-3 mt-4 text-left outlet-details">
        <strong class="font-weight-light text-uppercase body-2">Transaction Type</strong>
        <h3 class="font-weight-medium yellow--text mt-2">WITHDRAWAL</h3>
      </div>
      
      <v-sheet color="#2e3448" class="mb-4 pb-4  outlet-form">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="mb-5 wallet-form"
          @submit="submit()"
        >
          <p class="my-2 body-2">Amount</p>
          <v-text-field
            solo
            dense
            hide-details="auto"
            background-color="#58658d"
            type="number"
            placeholder="0"
            v-model="withdraw.amount"
            :loading="busy"
            :rules="[
              rules.required,
              rules.amountRule,
              withdrawAmountRule,
              creditsRule,
            ]"
            :error-messages="error && error.amount ? error.amount[0] : ''"
            required
          />
          <p class="my-2 body-2">Select Outlet</p>
            <v-select
                solo
                dense
                hide-details="auto"
                background-color="#58658d"
                :items="outlets"
                label="Bank Name"
                v-model="withdraw.outlet"
                item-text="name"
                item-value="id"
                :loading="busy"
                :rules="[rules.required]"
            />
          <span v-if="withdraw.outlet === 'gcash'">
            <p class="my-2 body-2">GCash Number</p>
            <v-text-field
              solo
              dense
              hide-details="auto"
              background-color="#58658d"
              v-if="withdraw.outlet === 'gcash'"
              type="number"
              v-model="withdraw.account_number"
              :loading="busy"
              :rules="[rules.required, rules.phone]"
              :error-messages="
              error && error.account_number ? error.account_number[0] : ''
              "
            />
          </span>
          <span v-if="withdraw.outlet === 'mlhuillier'">
            <p class="my-2 body-2">Receiver's Name</p>
            <v-text-field
              solo
              dense
              hide-details="auto"
              background-color="#58658d"
              type="text"
              v-model="withdraw.account_number"
              label="Receiver's Name"
              :loading="busy"
              :rules="[rules.required]"
              :error-messages="
              error && error.account_number ? error.account_number[0] : ''
              "
            />
          </span>
          <template v-if="withdraw.outlet === 'bank'">
            <p class="my-2 body-2">Bank Name</p>
            <v-select
              solo
              dense
              hide-details="auto"
              background-color="#58658d"
              :items="banks"
              label="Bank Name"
              v-model="withdraw.bank_name"
              item-text="name"
              item-value="id"
              :loading="busy"
              :rules="[rules.required]"
            />
            <p class="my-2 body-2">Account Name</p>
            <v-text-field
              solo
              dense
              hide-details="auto"
              background-color="#58658d"
              type="text"
              v-model="withdraw.account_name"
              :loading="busy"
              :rules="[rules.required]"
              :error-messages="
                error && error.account_name ? error.account_name[0] : ''
              "
            />

            <p class="my-2 body-2">Account Number</p>
            <v-text-field
              solo
              dense
              hide-details="auto"
              background-color="#58658d"
              type="number"
              v-model="withdraw.account_number"
              :loading="busy"
              :rules="[rules.required]"
              :error-messages="
                error && error.account_name ? error.account_name[0] : ''
              "
            />
            <v-text-field
              type="number"
              v-if="withdraw.bank_name === 'landbank'"
              v-model="withdraw.card_number"
              label="Card Number"
              :loading="busy"
              :rules="[rules.required]"
              :error-messages="
                error && error.card_name ? error.card_name[0] : ''
              "
            />
          </template>
        </v-form>
        <v-sheet light class="notes mb-5 pa-3 text-left" v-if="withdraw.outlet !== 'coordinator'">
         Before you proceed, please make sure that all details in the form are correct. Fees and charges will be paid by the requesting user.
        </v-sheet>
        <v-btn block tile color="primary" :loading="busy" @click="submit()">SUBMIT REQUEST</v-btn>
      </v-sheet>
      <withdraw-history />
    </v-container>
    <v-overlay :value="user.is_blocked" absolute opacity="1"
    >You are not allowed to withdraw</v-overlay
    >
  </div>
</template>

<script>
import mixin from "./../../mixins";
import rules from "./../../mixins/rules";
import helpers from "./../../mixins/helpers";
import WithdrawHistory from "./sections/WithdrawHistory";
import PageHeader from "@/sections/PageHeader";

export default {
  mixins: [mixin, rules, helpers],
  components: {
    WithdrawHistory,
    PageHeader,
  },
  data: () => ({
    mode: "withdrawals",
    outletLabel: "Outlet",
    selected: null,
    outlet: null,
    withdraw: {
      amount: null,
      outlet: null,
      receiver_name: null,
      receiver_number: null,
      account_name: null,
      account_number: null,
      contact_number: localStorage.getItem("mobile"),
    },
    valid: true,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    outletsStore() {
      return this.$store.state.outlets.withdrawals;
    },
    outlets() {
      if (this.outletsStore) {
        return this.outletsStore.outlets.filter((outlet) => {
          return outlet.visible;
        });
      }
      return [];
    },
    banks() {
      let selection = [];
      let obj = this.outletsStore.banks;
      Object.keys(obj).forEach((key) => {
        selection.push({ id: key, name: obj[key] });
      });
      return selection;
    },
    creditsRule() {
      return (
        this.withdraw.amount <= parseInt(this.user.credits) ||
        `You only have ${this.formatMoney(this.user.credits)} credits`
      );
    },
    withdrawAmountRule() {
      if (this.selected) {
        return (
          this.withdraw.amount >= parseInt(this.selected.min) ||
          `Minimum amount is ${this.formatMoney(this.selected.min)}`
        );
      }
      return true;
    },

    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    setOutlet(select) {
      this.withdraw.outlet = select;
      return (this.outlet = this.outlets.find((outlet) => {
        return outlet.id === select;
      }));
    },
    select(selected) {
      this.selected = this.outlets.find((outlet) => {
        return selected === outlet.id;
      });
    },
    reset() {
      this.$nextTick(() => {
        this.$refs.form.reset();
        this.withdraw.amount = 0;
        this.withdraw.contact_number = localStorage.getItem("mobile");
      });
    },
    setDefault() {
      this.selected = this.setOutlet('gcash')
    },

    //submitted_withdrawal
    submit() {
      if (this.$refs.form.validate()) {
        this.busy = true;
        let data = this.clean(this.withdraw);
        if (data.contact_number === "") data.contact_number = null;
        window.axios
          .post(
            `${window.SETTINGS.API_URL}/wallet/withdrawals`,
            data
          )
          .then((response) => {
            this.$store.dispatch("prompt", { message: response.data.message });
            this.reset();
            this.setDefault();
            this.$store.dispatch("appEvent", "withdrawal-submitted");
            window.fireRegisteredEvent('submitted_withdrawal', this.withdraw.amount, '');  
          })
          .catch((error) => {
            if (error && error.response)
              this.error =
                error.response.data.errors || error.response.data.message;
          })
          .finally(() => {
            this.busy = false;
          });
      }
    },
  },
  watch: {
    outlets() {
      this.setDefault();
    },
    $route() {
      this.setDefault();
    },
  },
  mounted() {
    this.getOutlets(this.mode);
  },
};
</script>
