<template>
  <div v-if="!client.is_firefox && hardwareAccelerationEnabled && !isMoz">
    <div class="main-player">
      <span class="reload-overlay">
        <v-chip
          class="ma-2"
          color="indigo"
          small
          text-color="white"
          @click="reload"
        >
          <v-avatar left>
            <v-icon size="18">mdi-cached</v-icon>
          </v-avatar>
          Reload
        </v-chip>
      </span>
      <video
        id="video-player-main"
        disablepictureinpicture
        style="width: 100%; height: 100%; position: absolute; z-index: 0;pointer-events: none;"
        class="video-js vjs-default-skin vjs-live"
        playsinline
      ></video>
    </div>
    <v-overlay
      absolute
      opacity="1"
      z-index="1"
      :value="client.lock || cantStream || !playing"
    >
      <div class="text-center px-4" v-if="client.lock">
        <span v-if="client.status == 'locked'">{{ client.lock_message || '.' }}</span>
        <img v-else-if="client.status == 'locked-image'" :src="client.lock_message" style="max-width: 100%;" />
      </div>
      <div class="text-center px-4" v-else-if="cantStream">
        You are not allowed to view stream
      </div>
      <template v-else>
        <div v-show="canPlay" @click.stop.prevent="play" class="text-center">
          <v-icon :size="error ? 50 : 90" color="#bbbbbb" class="play-icon">
            mdi-play-circle
          </v-icon>
        </div>
        <div class="text-center" style="opacity: 0.5;">UFC / {{ userID }}</div>
      </template>
    </v-overlay>
  </div>

  <div v-else-if="client.is_firefox || isMoz" class="main-player">
    <div class="text-center py-12">
      <v-img src="./../assets/allowed-browsers-only.png" />
    </div>
  </div>

  <div v-else-if="!hardwareAccelerationEnabled" class="main-player p-relative">
    <div class="text-h5 text-sm-h4 font-weight-black p-absolute text-center px-lg-10 py-10 py-lg-16"><div class="mt-5 mt-md-16">Please Enable Hardware Accelaration On Your Browser</div></div>
  </div>
</template>

<script>

import _ from "lodash";

export default {
  props: ["client", "cantStream","userID"],
  data: () => ({
    player: null,
    playing: false,
    buffering: false,
    error: false,
    video_url: null,
    showUserId: false,
    overlayContainerKey:Math.random(),
    paused_:false
  }),
  computed: {
    isMoz() {
      if(window.mozInnerScreenX||window.mozInnerScreenY){        
        return true
      }else{
        return false;
      }
    },
    canPlay() {
      return this.client && this.client.video_url && !this.playing;
    },
    isOffline() {
      return false;
    },
    ios() {
      return window.isIOS || !!window.safari;
    },
    hardwareAccelerationEnabled() {
      var canvas = document.createElement('canvas');
      try {
        var gl = canvas.getContext('webgl', { powerPreference: "high-performance" }) || canvas.getContext('experimental-webgl', { powerPreference: "high-performance" });
      } catch (e) {
        console.log(e)
      }

      if (gl) {
        var debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        var vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
      }
      if (vendor=="Google Inc. (Google)"){
        return false;
      }
      else{
        return true;
      }
    }
  },
  methods: {
    initPlayer() {
      // let self = this;
      if (!this.player) {
        // eslint-disable-next-line no-undef
        this.player = videojs("video-player-main", {
          html5: {
            nativeVideoTracks: false,
            nativeAudioTracks: false,
            nativeTextTracks: true,
          },
          liveui: false,
          autoplay: false,
          suppressNotSupportedError: true,
          notSupportedMessage: "No live stream available",
        });

        let playerConfig = {
          type: "application/x-mpegURL",
          src: this.client.video_url,
        };

        if(this.client.drm_type) {
          let licenseUri = "https://license-global.pallycon.com/ri/licenseManager.do";
          let fairplayCertUri = "https://license-global.pallycon.com/ri/fpsKeyManager.do?siteId=LO89";
          let drmName;

          this.player.eme();
          if(this.client.drm_type == 'FairPlay') {
            let vue = this;
            playerConfig = {
                src: this.client.video_url,
                type: 'application/x-mpegurl',
                keySystems: {
                    'com.apple.fps.1_0': {
                        getCertificate: function (emeOptions, callback) {
                            window.videojs.xhr({
                                url: fairplayCertUri,
                                method: 'GET',
                            }, function (err, response, responseBody) {
                              if (err) {
                                callback(err)
                                return;
                              }
                              callback(null, window.base64DecodeUint8Array(responseBody));
                            });
                        },
                        getContentId: function (emeOptions, initData) {
                            const contentId = "https://" + window.arrayToString(initData);
                            return contentId.substring(contentId.indexOf('skd://') + 6);
                        },
                        getLicense: function (emeOptions, contentId, keyMessage, callback) {
                            window.videojs.xhr({
                                url: licenseUri,
                                method: 'POST',
                                responseType: 'text',
                                body: 'spc=' + window.base64EncodeUint8Array(keyMessage),
                                headers: {
                                  'Content-type': 'application/x-www-form-urlencoded',
                                  'pallycon-customdata-v2': vue.decrypt(vue.client.drm_token)
                                }
                            }, function (err, response, responseBody) {
                                if (err) {
                                  callback(err)
                                  return;
                                }
                                callback(null, window.base64DecodeUint8Array(responseBody));
                            });
                        }
                    }
                }
            };
          } else {
            if(this.client.drm_type == 'Widevine') {
              drmName = 'com.widevine.alpha';
            } else if(this.client.drm_type == 'PlayReady') {
              drmName = 'com.microsoft.playready';
            }

            playerConfig = {
                src: this.client.video_url,
                type: 'application/dash+xml'
            };

            playerConfig.keySystemOptions = [];
            playerConfig.keySystemOptions.push({
              name: drmName,
              options: {
                  serverURL: licenseUri,
                  httpRequestHeaders:{
                      'pallycon-customdata-v2': this.decrypt(this.client.drm_token)
                  }
              }
            });
          }
        }
        
        this.player.src(playerConfig);

        this.player.on('dblclick', function() {
            window.location.reload();
        });

        this.$nextTick(() => {
          this.removeControls();
          this.playing = false
          // this.ios ? this.playing = false : this.playing = true 
        });
      } else if (this.client.lock || this.cantStream) {
        this.stop();
      } 
    },
    checkVideo() {
      console.log("check video");
      if (this.client && this.client.video_url) {
        if (this.client.video_url !== this.video_url) {
          this.video_url = this.client.video_url;
          this.$nextTick(() => {
            this.initPlayer();
          });
        }
      }
    },
    play() {
      if (!this.player || this.cantStream) return;
      try {
        this.player.play();
        this.player.muted(false);
        this.playing = true;
      } catch (er) {
        this.playing = false;
        console.log(er);
      }
    },
    pauseVid(){
        if(this.playing){
          this.playing = false;
          try{
            this.player.pause();
          }catch(e){
            console.log(e);
          }
        }
    },
    stop() {
      this.playing = false;
      if (this.player) {
        this.player.muted(true);
        this.player.pause();
      }
    },
    removeControls() {
      try {
        document.getElementsByClassName("vjs-mute-control")[0].remove();
        document.getElementsByClassName("vjs-volume-panel")[0].remove();
        if (!this.ios) {
          document.getElementsByClassName("vjs-play-control")[0].remove();
          document.getElementsByClassName("vjs-big-play-button")[0].remove();
          document.getElementsByClassName(
            "vjs-control-bar"
          )[0].style.paddingLeft = "10px";
        }
      } catch (e) {
        console.log(e);
      }
    },
    destroyPlayer() {
      try {
        if (this.player) {
          this.stop();
          var oldPlayer = document.getElementById("video-player-main");
          // eslint-disable-next-line no-undef
          videojs(oldPlayer).dispose();
        }
        this.player = null;
      } catch (e) {
        console.log(e);
      }
    },
    decrypt(value){
      return JSON.parse(window.CryptoJS.AES.decrypt(value, this.userID + window.SETTINGS.SALT, { format: window.CryptoJSAesJson }).toString(window.CryptoJS.enc.Utf8))
    },
    reload() {
      window.location.reload();
    },
    randomize(){
        var $this = this;
        const rnd_id = (length) => {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }
          return result;
         }
        const addWaterMark = (ar)=>{
          var old_element = [];
            var elementType = ['div',"span","p","h1"];
            var index = Math.floor(Math.random() * 4) + 0;
            var top = Math.floor(Math.random() * 75) + 1;
            var right = Math.floor(Math.random() * 75) + 1;
            _.each(ar, (o)=>{
                o.style.zIndex = 0;
                var newID = rnd_id(6);
                var new_element = document.createElement(elementType[index]);
                    new_element.innerHTML = $this.userID;
                    new_element.style = "z-index:999999;position: absolute;z-index: 10;font-size: 35px;font-weight: 600;opacity: 0.5;top:"+top+"%;right:"+right+"%";
                    new_element.id = newID;
                    o.parentElement.appendChild(new_element);
                    old_element.push(new_element);
            })
            return old_element;
        }
        var old_ids = [];
        const videoPlayer = document.getElementsByTagName('video');
        var idInterval = setInterval(function () {
          if($this.showUserId){
            if(old_ids){
              _.each(old_ids, (o)=>{
                      try{
                          o.remove();
                      }catch(e){
                          console.log(e)
                      }
                })
            }
            old_ids = addWaterMark(videoPlayer);
          }else{
            if(old_ids){
              _.each(old_ids, (o)=>{
                      try{
                          o.remove();
                      }catch(e){
                          console.log(e)
                      }
                })
            }
          }
          
          if(!$this.showUserId){
            clearInterval(idInterval);
          }
        }, 2000);
    },
    streamDefense(){
      let videoPlayer = document.getElementsByTagName("video");
      document.addEventListener('keydown',function(event){
          if(event.code == "F11"){
              event.preventDefault();
              document.exitFullscreen();
          }
      });

      window.addEventListener("beforeunload", function() {
          let pipElement = document.pictureInPictureElement
          pipElement.pause()
          document.exitPictureInPicture()
      });

      window.addEventListener('beforeunloadcancelled', function () {
          let pipElement = document.pictureInPictureElement
          pipElement.pause()
          document.exitPictureInPicture()
      });

      document.addEventListener("fullscreenchange", function() {
        if (document.fullscreen) {
        _.each(videoPlayer, (o)=>{
            if(o.webkitDisplayingFullscreen){
                this.removeTag(o);
                document.exitFullscreen();
            }
        })
        }
      });

      document.addEventListener("enterpictureinpicture", function() {
        let videoPlayer = document.getElementsByTagName("video");
          _.each(videoPlayer, (o)=>{
            if (document.pictureInPictureElement) {
              document.exitPictureInPicture();
              this.removeTag(o)
            }
          })
      });

      _.each(videoPlayer, (o)=>{
          o.requestPictureInPicture = ()=>{
            o.remove();
            return false; 
          }
          o.requestFullscreen = ()=>{
            o.remove();
            return false; 
          }
          o.webkitEnterFullscreen = ()=>{
            o.remove();
            return false; 
          }
          o.webkitEnterFullScreen = ()=>{
            o.remove();
            return false; 
          }
          o.webkitRequestFullscreen = ()=>{
            o.remove();
            return false; 
          }
          o.webkitRequestFullScreen = ()=>{
            o.remove();
            return false; 
          }
      })
      setInterval(() => {
        let pipElement = document.pictureInPictureElement;
        window.onbeforeunload = function() {
          if(pipElement){
            document.exitPictureInPicture()
          }
        }
          if(pipElement){
              pipElement.onplaying =  function() {
                  pipElement.pause()
              }
              pipElement.onplay =  function() {
                  pipElement.pause()
              }
              pipElement.play = function(){
                  pipElement.pause()
              }
              document.exitPictureInPicture();
          }
      }, 1000);

    },
    videoStyleObserver(){
        var $this = this;
        var _d = document.implementation.createDocument ('sabong', 'html', null);
        var _el = _d.createElement("span");
        let videoPlayer = document.getElementsByTagName("video");
        const targetNode = document.body;
        const config = { attributes: true, childList: true, subtree: true };
        const callback = function(mutationList) {
        for(const mutation of mutationList) {
          _.each(videoPlayer, (o)=>{
            //renew the remove function of the video if hacdker will overwrite using injection
              o.remove = _el.remove
            // listen if video theft trying to modify the styles 
              if(o == mutation.target && mutation.attributeName == "style"){
                $this.removeTag(o);
              }
              if(o.parentElement == mutation.target && mutation.attributeName == "style"){
                $this.removeTag(o);
              }
              if(mutation.attributeName == "disablepictureinpicture"){
                if(document.pictureInPictureElement){
                  document.exitPictureInPicture();
                }
              }

            // listen if video theft trying to modify the class
              if(o.parentElement){
                var parentComputedStyle = getComputedStyle(o.parentElement)
                var videoComputedStyle = getComputedStyle(o)
                if(videoComputedStyle.position == "fixed" || parseInt(videoComputedStyle.zIndex) > 0 || parentComputedStyle.position == "fixed" || parseInt(parentComputedStyle.zIndex) > 0){
                  $this.removeTag(o);
                  if(document.pictureInPictureElement){
                    document.exitPictureInPicture();
                  }
                }
              }

            //renew the remove function of the video if hacdker will overwrite using injection
              const classList = ["marginBottom","marginTop","marginRight","marginLeft","scrollMarginLeft","scrollMarginRight","scrollMarginTop","top","bottom","left","right","x","y"]
              setTimeout(() => {              
                _.each(classList, (c)=>{
                  if(getComputedStyle(o)[c] != "0px" ||getComputedStyle(o.parentElement)[c] != "0px"){
                      $this.removeTag(o);
                  }
                });
              }, 5000);
              document.exitFullscreen = _d.exitFullscreen;
              document.exitPictureInPicture = _d.exitPictureInPicture;
              if(document.pictureInPictureElement){
                document.exitPictureInPicture()
              }
            });
          }
      };
      const observer = new MutationObserver(callback);
      observer.observe(targetNode, config);
    },
    removeTag(el){
      var _d = document.implementation.createDocument ('sabong', 'html', null);
      var _el = _d.createElement("span");
          el.remove = _el.remove;
          if(this.showUserId){
          el.remove();
          }
    }
  },
  watch: {
    "client.video_url" () {
      console.log("source watcher");
      this.$nextTick(() => {
        this.checkVideo();
      });
    },
    "client.lock"() {
      console.log("video lock + " + this.client.lock);
      this.client.lock ? this.stop() : this.play();
    },
    "client.show_user_id"() {
      this.showUserId = this.client.show_user_id ? true : false;
      this.overlayContainerKey = Math.random();
      if(this.showUserId){
        this.pauseVid()
        this.randomize()
      }
    },
  },
  beforeDestroy() {
    this.destroyPlayer()
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.player) this.checkVideo();
    });
    if(this.client) {
      this.showUserId = this.client.show_user_id;
    }
    this.streamDefense();
    this.videoStyleObserver();
  },
}; 
</script>

<style>
video::-webkit-media-controls-panel
{
    display: none !important;
}
span.reload-overlay {
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 4;
  opacity: 0.5;
}
.reload-overlay:hover {
  opacity: 1 !important;
}
</style>
