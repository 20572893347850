<template>
  <div class="">
    <v-row>
      <v-col class="pt-0">
        <v-card class="mx-auto white--text" color="#1a2136" flat>
          <div class="d-flex pa-4 user-head">
            <span class="mr-2">
              <v-avatar>
                <img src="@/assets/profile3.jpg" alt="John" />
              </v-avatar>
            </span>
            <span>
              <p class="body-1 mb-0">{{ user.name }}</p>
              <p class="caption mb-0">{{ user.mobile }}</p>
            </span>
            <div class="ml-auto">
              <v-btn
                small
                icon
                color="#ffffff6b"
                @click="goTo('/help')"
                v-if="mobile"
              >
                <v-icon size="">mdi-help-circle</v-icon>
              </v-btn>

              <v-btn
                small
                icon
                color="#ffffff6b"
                @click="openCreditModal('help')"
                v-else
              >
                <v-icon size="">mdi-help-circle</v-icon>
              </v-btn>
            </div>
          </div>

          <div class="acc-devider"></div>
          <v-card-text>
            <p class="caption grey--text text-lighten-1 mb-0">
              Account Points
            </p>
            <p class="mb-0  credit-bal font-weight-bold">
              {{ formatMoney(user.credits) }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="ma-0 ">
      <v-col cols="6" class="pr-1 pa-0">
        <v-btn
            tile
            depressed
            block
            small
            color="#009900"
            @click="goTo('/add-credits')"
            v-if="mobile"
        >
          Add Points
        </v-btn>
        <v-btn
            tile
            depressed
            block
            small
            color="#009900"
            v-else
            @click="openCreditModal('add Credits')"
        >
          Add Points
        </v-btn>
      </v-col>
      <v-col cols="6" class="pl-1 pa-0">
        <v-btn
            tile
            depressed
            block
            small
            color="warning"
            @click="goTo('/withdraw')"
            v-if="mobile"
        >
          Withdraw
        </v-btn>
        <v-btn
            tile
            depressed
            block
            small
            v-else
            color="warning"
            @click="openCreditModal('withdraw')"
        >
          Withdraw
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="addCreditModal" width="600px">
      <v-card color="#2e3448">
        <v-card-title class="body-2 text-uppercase blue darken-3 py-2 px-3">
          {{ selectedModal }}
          <v-spacer></v-spacer>
          <v-btn icon dark small @click="addCreditModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text >
          <help v-if="selectedModal === 'help'" />
          <deposit class="mt-2" v-else-if="selectedModal === 'add Credits'" />
          <withdraw v-else-if="selectedModal === 'withdraw' && user.mobile_verified" />
          <verify :isModal="true" :willReset="resetVerifyPage" v-else />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import helpers from "@/mixins/helpers";
// import AddCredits from "@/components/AddCredits";

import Deposit from "@/views/wallet/Deposit";
import Withdraw from "@/views/wallet/Withdraw.vue";
import Help from "@/views/Help.vue";
import Verify from "@/views/Verify.vue";
export default {
  mixins: [helpers],
  data: () => ({
    addCreditModal: false,
    selectedModal: null,
    resetVerifyPage: false,
  }),
  components: {
    Deposit,
    Withdraw,
    Help,
    Verify,
  },
  methods: {
    openCreditModal(modal) {
      this.selectedModal = modal;
      this.addCreditModal = true;
    },
    goTo(path) {
      if(!this.user.mobile_verified && path === "/withdraw"){
        this.$router.push({ path: "/verify" }).catch(() => {
          this.drawer = false;
        });
      }else{
        this.$router.push({ path: path }).catch(() => {
          this.drawer = false;
        });
      }
    },
  },
  watch: {
    addCreditModal: function (value) {
      this.resetVerifyPage = value;
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },

    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    // console.log(this.$store.state)
  }
};
</script>

<style>
p.credit-bal {
  font-size: 20px;
  color: #ffca4f !important;
}
</style>
