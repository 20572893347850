<template>
  <div >
    <div v-if="loading" class="px-3 py-2 muted-2">Loading...</div>
    <div class="d-flex trend-info  ">
      <v-btn
        x-small
        depressed
        tile
        dark
        class="mr-2 pr-1 pl-0 "
        color="#22273a"
      >
        <v-avatar color="#00000033" size="20" class=" mr-1 trend-meron" tile>
          {{ countMeron }}
        </v-avatar>
        MERON
      </v-btn>
      <v-btn
        x-small
        depressed
        tile
        dark
        class="mr-2 pr-1 pl-0 "
        color="#22273a"
      >
        <v-avatar color="#00000033" size="20" class=" mr-1 trend-wala" tile>
          {{ countWala }}
        </v-avatar>
        WALA
      </v-btn>
      <v-btn x-small depressed tile dark class="mr-2 pr-1 pl-0" color="#22273a">
        <v-avatar color="#00000033" size="20" class=" mr-1 trend-draw" tile>
          {{ countDraw }}
        </v-avatar>
        DRAW
      </v-btn>
      <v-btn
        x-small
        depressed
        tile
        dark
        class="mr-2 pr-1 pl-0 "
        color="#22273a"
      >
        <v-avatar color="#000000a3" size="20" class=" mr-1 trend-cancel" tile>
          {{ countCancelled }}
        </v-avatar>
        CANCELLED
      </v-btn>
    </div>

    <v-divider class="mt-3 devider-top" color="#191C2E"></v-divider>
    <v-divider class="mb-2 devider-bot" color="#424769"></v-divider>

    <div class="trend-container ">
      <div class="d-flex flex-column">
        <div
          class="d-flex "
          v-for="(row, index) in trends"
          :key="index + 2 * 100"
        >
          <div
            class="test  "
            v-for="(col, index2) in row"
            :key="index2 + 8 * 100"
          >
            <v-avatar
              size="25"
              tile
              v-if="col.side == types.cancelled"
              class="trend-item trend-cancel "
            >
              <p class="mb-0 caption" v-text="col.num"></p>
            </v-avatar>
            <v-avatar
              size="25"
              tile
              v-if="col.side == types.meron"
              class="trend-item trend-red "
            >
              <p class="mb-0 caption" v-text="col.num"></p>
            </v-avatar>
            <v-avatar
              size="25"
              tile
              v-if="col.side == types.wala"
              class="trend-item trend-blue "
            >
              <p class="mb-0 caption" v-text="col.num"></p>
            </v-avatar>
            <v-avatar
              size="25"
              tile
              v-if="col.side == types.draw"
              class="trend-item trend-jackpot "
            >
              <p class="mb-0 caption" v-text="col.num"></p>
            </v-avatar>
            <v-avatar
              size="25"
              tile
              v-if="col.side == types.empty"
              class="trend-item trend-empty "
            >
              <p class="mb-0 caption" v-text="col.num"></p>
            </v-avatar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data: () => ({
    loading: false,
    types: {
      cancelled: -1,
      meron: 1,
      wala: 2,
      draw: 3,
      empty: 0,
    },
  }),
  computed: {
    trends() {
      return this.$store.getters.trends;
    },
    flattenTrends() {
      return _.flatten(this.trends);
    },
    countMeron() {
      return _.filter(
        this.flattenTrends,
        (trend) => trend.side == this.types.meron
      ).length;
    },
    countWala() {
      return _.filter(
        this.flattenTrends,
        (trend) => trend.side == this.types.wala
      ).length;
    },
    countDraw() {
      return _.filter(
        this.flattenTrends,
        (trend) => trend.side == this.types.draw
      ).length;
    },
    countCancelled() {
      return _.filter(
        this.flattenTrends,
        (trend) => trend.side == this.types.cancelled
      ).length;
    },

    mobile() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
};
</script>

<style lang="sass">
@import './../sass/colors.scss'

.table-responsive
    overflow-x: auto

.trend-table
    font-size: 22px
    padding: 0
    margin: 0
    line-height: 14px
    text-align: center
    border: 1px solid #222
    border-collapse: collapse
    td, tr
        padding: 4px
        margin: 0
        border: 1px solid #1e1e1e

.trend-item
    background: #21263a
    margin: 3px 6px 3px 0

.trend-red
    background: $side-red-color

.trend-blue
    background: $side-blue-color

.trend-jackpot
    background: $side-jackpot-color


.trend-meron
    background: $side-red-color !important

.trend-wala
    background: $side-blue-color !important

.trend-draw
    background: $side-jackpot-color !important

.trend-cancel
    background: $cancelled-color !important
.game-trend-cont
    background: #2e3349 !important


.trend-container
    overflow: hidden
    overflow-x: scroll
     
</style>
