<template>
  <v-card color="#2e3448" tile flat class="py-4">
    <h3 class="mb-2">UPDATE NAME</h3>
    <v-form
      ref="formName"
      v-model="valid"
      lazy-validation
      class="mb-4 wallet-form"
      @submit="submit()"
    >
      <p class="my-2 body-2">Name</p>
      <v-text-field
        solo
        hide-details="auto"
        background-color="#58658d"
        dense
        v-model="update.name"
        type="text"
        :loading="busy"
        :rules="[rules.required]"
        :error-messages="error && error.name ? error.name[0] : ''"
        @keypress.enter="submit('name')"
        required
      ></v-text-field>
    </v-form>
    <v-btn tile block depressed color="primary" :loading="busy" @click="submit()"
      >UPDATE</v-btn
    >
  </v-card>
</template>

<script>
import data from "./../mixins/data";
import rules from "./../mixins/rules";

export default {
  mixins: [data, rules],
  data: () => ({
    busy: false,
    error: null,
    update: {
      name: null,
    },
    valid: true,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    submit() {
      if (this.$refs.formName.validate()) {
        this.postData(
          `/${window.SETTINGS.API_SLUG}/account`,
          this.update,
          (data) => {
            let user = data.data.data;
            localStorage.setItem("user", JSON.stringify(user));
            this.$store.dispatch("setUser", user);
            this.$store.dispatch("prompt", {
              message: "Account name updated!",
            });
          },
          null,
          "PUT"
        );
      }
    },
  },
  watch: {
    "user.name"() {
      this.update.name = this.user.name;
    },
  },
  mounted() {
    this.update.name = this.user.name;
  },
};
</script>
