<template>
  <div>
    <v-row >
      <v-col class="pb-1">
        <div class="d-flex align-center">
          <p class="mb-0 body-2" >BETS</p>
          <v-spacer ></v-spacer>
          <v-btn 
            text
            small
            color="yellow"
            class="mb-0"
            style="margin-right: -1em"
            @click.stop="mobile ? $router.push({ name: 'advance' }) : openAdvanceModal('Advance Bets')"
          > 
            <v-icon 
              dark 
              size="14"
              class="mr-1"

            >
              mdi-clock-time-four-outline
            </v-icon>
              Advance Bets
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-divider class="mt-0 devider-top " color="#191C2E"></v-divider>
    <v-divider class="mb-2 devider-bot " color="#424769"></v-divider>
    
    <v-row v-if="bets" class="">
      <v-col cols="12" v-if="history && bets.length < 1">
        <div class="text-lose mb-3 text-center">
          <small>You have no bets yet</small>
        </div>
      </v-col>

      <bet-list
        v-for="(bet, index) in bets"
        :key="index"
        :num="bet.game.num"
        :winner="bet.game.winner"
        :is-advanced="bet.is_advanced"
        :side="sideColors[bet.side - 1]"
        :amount="formatMoney(bet.amount)"
      >
        <span v-if="bet.game.low_bet" class="text-lose">
          <p class="body-2 mb-0">RETURNED</p>
        </span>
        <span v-else-if="bet.game.new_winner">
          <p
            class="body-2 mb-0 green--text font-weight-bold"
            v-if="bet.side === bet.game.new_winner"
          >
            WIN <span class="text-lose">DISPUTED</span>
          </p>
          <p class="body-2 mb-0 text-lose" v-else-if="bet.side === 4">
            CANCELLED DISPUTED
          </p>
          <p class="body-2 mb-0 text-lose" v-else>LOSE DISPUTED</p>
        </span>
        <span v-else-if="bet.game.cancelled" class="text-lose">
          <p class="body-2 mb-0">
            {{ bet.is_distributed ? "RETURNED" : "CANCELLED" }}
          </p>
        </span>
        <span v-else-if="bet.win === 1" class="d-flex">
          <span class="green--text font-weight-bold">
            <p class="mb-0 body-2">WIN {{ formatMoney(bet.win_amount) }}</p>
          </span>
          <small class="ml-2 bet-history-odds">
            {{ (bet.game.multi * 100) | numeral(0, 0) }}%
          </small>
        </span>
        <span v-else-if="bet.is_distributed && bet.win !== 0" class="text-lose">
          <p class="body-2 mb-0">RETURNED - DRAW</p>
        </span>
        <span v-else-if="bet.win === 0" class="text-lose">
          <p class="body-2 mb-0">LOSE</p>
        </span>
      </bet-list>
    </v-row>

    <div v-if="hasMore && bets.length" class="my-3 text-center">
      <v-btn
        text
        small
        :loading="loading"
        @click.stop.prevent="loadMore(page + 1, 0)"
      >
        More <v-icon size="14" class="ml-1">mdi-chevron-down</v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="advanceBetModal" width="600px">
      <v-card color="#2e3448">
        <v-card-title class="body-2 text-uppercase blue darken-3">
          {{ selectedItem }}
          <v-spacer></v-spacer>
          <div class="float-right">
            <v-btn icon dark small @click="advanceBetModal = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <v-card-text >
          <advance-betting />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import helpers from "./../mixins/helpers";
import BetList from "./../components/BetList";

import AdvanceBetting from "@/views/AdvanceBetting.vue";

export default {
  mixins: [helpers],
  props: ["sideColors", "loadMore"],
  data: () => ({
    selectedItem: null,
    advanceBetModal: false,
  }),
  components: {
    BetList,
    AdvanceBetting,
  },
  methods: {
    openAdvanceModal(item) {
      this.selectedItem = item;
      this.advanceBetModal = true;
    },
  },
  computed: {
    bets() {
      return this.history && this.history.data
        ? this.sort(this.getUnique(this.history.data, "id"))
        : [];
    },
    history() {
      return this.$store.state.history;
    },
    page() {
      return this.history ? this.history.meta.current_page : 0;
    },
    hasMore() {
      return this.history && this.history.meta
        ? this.page < this.history.meta.last_page
        : false;
    },
    loading() {
      return this.$store.state.loading;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    bets() {
      if (this.bets && this.bets.length && this.page === 1)
        localStorage.setItem("last-game-history", this.bets[0].game.num);
    },
  },
};
</script>

