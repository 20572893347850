<template>
  <v-container class="fill-height login-container" fluid>
    <v-row>
      <v-col v-if="has_code" cols="12 " class=" mx-auto ">
          <div class="d-flex flex-column flex-sm-row align-center justify-center">

            <div v-if="!isModal" class="text-center ">
            <img src="./../assets/ufc-logo.png" class="login-logo mb-0" />
            
            </div>
            <v-form ref="form" class="main-form" v-model="valid" lazy-validation @submit="submit()">
                <h4>Verification</h4>
            <p class="mt-4 auth-note" v-if="credentials.mobile">
              Please verify your mobile number by providing the One Time Pin we
              sent to
              <strong>{{ credentials.mobile }}</strong>
            </p>
            <p class="body-2 my-2 white--text">One Time Pin</p>
          <v-text-field
            v-model="credentials.pin"
            label="One Time Pin"
            type="number"
            :rules="[rules.required]"
            :error-messages="error || ''"
            @keypress.enter="submit()"
            required
            background-color="#c0c2ce33"
            solo
            dense
            hide-details="auto"
          ></v-text-field>
          <div class="text-center mt-4">
            <v-btn
              color="warning"
              class="mb-6"
              @click="submit()"
              :loading="busy"
              block
            >
              Verify
            </v-btn>
            <p class="mt-5 auth-note mb-1">Didn't recieve your One Time Pin?</p>
            <v-btn
              color="info"
              block
              
              class="mb-0"
              @click.stop="reSend()"
              :loading="busy"
              :disabled="disableResend"
            >
              Resend pin
            </v-btn>
            <br />
            <v-btn v-if="!isModal" color="#fff" outlined block  @click.stop="backToAccount()">Back</v-btn>
          </div>
            </v-form>
          </div>
      </v-col>
      <v-col v-else cols="8" offset="2" class=" mx-auto ">
        <div class="align-center justify-center">
          <h4>Verify Account</h4>
          <p class="mt-4 auth-note" v-if="credentials.mobile">
            We need to send a One Time Pin to your mobile number <strong>{{ credentials.mobile }}</strong> to verify your account.
          </p>
          <v-btn
              color="warning"
              class="mb-6"
              @click="reSend()"
              :loading="busy"
              block
          >
            Send OTP
          </v-btn>
          <div class="text-center">
            <a @click.prevent="has_code = true">Already have OTP</a>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import auth from "./../mixins/auth";
import rules from "./../mixins/rules";

export default {
  mixins: [mixin, auth, rules],
  data: () => ({
    valid: true,
    withMobile: true,
    disableResend: false,
    has_code: false,
    credentials: {
      pin: null,
      mobile: null,
    },
  }),
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    willReset: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) this.verify(this.credentials, this.isModal);
    },
    reRegister() {
      localStorage.removeItem("verified");
      this.$router.push({ name: "login" });
    },
    reSend() {
      this.has_code = true;
      this.disableResend = true;
      this.reSendPin(this.credentials);
      setTimeout(() => {
        this.disableResend = false;
      }, 30000);
    },
    backToAccount() {
      this.$router.push({ name: "account" });
    }
  },
  mounted() {
    this.$nextTick(() => {
      let registered = localStorage.getItem("user");
      registered
        ? (this.credentials.mobile = JSON.parse(registered).mobile)
        : (this.withMobile = false);
    });
    this.has_code = false;
  },
  watch: {
    willReset: function (value) {
      if(value)this.has_code = false;
    }
  },
};
</script>