<template>
  <div class="">
    <v-card class="mx-auto ufc-cards">
      <v-list dense>
        <v-subheader class="ml-2">Account Settings</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item @click="setItem('settings')" v-if="mobile">
            <v-list-item-icon>
              <v-icon>mdi-pencil-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Edit Account </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="openSettingsModal('settings')" v-else>
            <v-list-item-icon>
              <v-icon>mdi-pencil-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Edit Account</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="setItem('promo')" v-if="mobile">
            <v-list-item-icon>
              <v-icon >
                mdi-gift
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Promo</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="openSettingsModal('promo')" v-else>
            <v-list-item-icon>
              <v-icon >
                mdi-gift
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Promo</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="setItem('transactions')" v-if="mobile">
            <v-list-item-icon>
              <v-icon> mdi-format-align-left</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Transactions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="openSettingsModal('transactions')" v-else>
            <v-list-item-icon>
              <v-icon> mdi-format-align-left</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Transactions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="setItem('cash-in')" v-if="mobile">
            <v-list-item-icon>
              <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Cash in Requests</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="openSettingsModal('cash-in')" v-else>
            <v-list-item-icon>
              <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Cash in Requests</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-btn  v-if="!mobile" block class="mt-3 text-capitalize" color="#ff5100" @click="openHelp()" depressed tile>
        <v-icon class="mr-1">mdi-message-text-outline</v-icon>
            Contact Us 
    </v-btn>

<!--     <v-btn v-if="user.inv_link" block class="mt-3 text-capitalize" color="primary" @click.stop="showQR()" depressed tile>
            Invite your friends and earn!
    </v-btn> -->


    <v-row>
      <v-col>
        <v-dialog v-model="settingsModal" width="600px">
          <v-card color="#2e3448">
            <v-card-title class="body-2 text-uppercase blue darken-3">
              {{ selectedItem }}
              <v-spacer></v-spacer>
              <div class="float-right">
                <v-btn icon dark small @click="settingsModal = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-card-title>

            <v-card-text style="min-height:650px;">
              <transactions v-if="selectedItem === 'transactions'" />
              <cash-ins v-if="this.selectedItem === 'cash-in'" />

              <settings v-if="this.selectedItem === 'settings'" />
              <promo v-if="this.selectedItem === 'promo'" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mixin from "./../mixins";

import helpers from "./../mixins/helpers";

import Transactions from "@/views/wallet/Transactions.vue";
import CashIns from "@/views/wallet/CashIns.vue";
import Settings from "@/views/Settings.vue";
import Promo from "@/views/Promo.vue";
export default {
  mixins: [mixin, global, helpers],

  data: () => ({
    selectedItem: null,
    settingsModal: false,
  }),
  components: {
    Transactions,
    CashIns,
    Settings,
    Promo
  },

  methods: {

    showQR() {
      this.$store.dispatch("showQRModal");
    },
    setItem(item) {
      this.selectedItem = item;

      if (this.selectedItem === "transactions") {
        this.goTo("/transactions");
      }

      if (this.selectedItem === "cash-in") {
        this.goTo("/cash-ins");
      }

      if (this.selectedItem === "settings") {
        this.goTo("/settings");
      }
      if (this.selectedItem === "promo") {
        this.goTo("/promo");
      }
    },
    goTo(path) {
      this.$router.push({ path: path }).catch(() => {
        this.drawer = false;
      });
    },
    reload() {
      window.location.reload();
    },
    openSettingsModal(item) {
      this.selectedItem = item;
      this.settingsModal = true;
    },
    logout() {
      this.drawer = false;
      this.$emit("logout");
    },
     openHelp() {
      window.open(window.SETTINGS.LC_URL, "_system");
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style>
.ufc-cards .v-list {
  background-color: #1a2136 !important;
}
</style>
