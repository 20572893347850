<template>
  <v-toolbar color="#232731" dense flat>
    <v-btn icon @click.stop="goBack()">
      <v-icon color="white">mdi-arrow-left</v-icon>
    </v-btn>

    <p class="body-1 mb-0 ">{{ title }}</p>

    <v-spacer></v-spacer>

    <div><slot></slot></div>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Results",
    },
    backButton: {
      type: String,
      default: "play",
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  }
};
</script>

<style lang="sass" scoped></style>
